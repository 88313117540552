import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Event } from '@models/events/event.model';
import { EventService } from '@services/events/event.service';

@Injectable()
export class EventStore {
  private _event: BehaviorSubject<Event | undefined> = new BehaviorSubject<
    Event | undefined
  >(undefined);

  private _errorMessage: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string | undefined>(undefined);

  public readonly event: Observable<Event | undefined> =
    this._event.asObservable();

  public readonly errorMessage: Observable<string | undefined> =
    this._errorMessage.asObservable();

  constructor(private eventService: EventService) {}

  getEventByUri(uri: string, lsParams?: Map<string, any>) {
    this.eventService.getByUri(uri, lsParams).subscribe({
      next: (event) => {
        this._event.next(event);
      },
      error: (error) => {
        if (error?.error) {
          this._errorMessage.next(error.error);
        }
      },
    });
  }

  setEvent(event: Event) {
    this._event.next(event);
  }

  refreshEvent(eventUri: string, lsEventUuid?: string) {
    let lsParams = new Map<string, any>();
    if (lsEventUuid) {
      lsParams.set('lsEventUuid', lsEventUuid);
    }

    const lsEnteredAccessCodeForEventID = localStorage.getItem(
      'enteredAccessCodeForEventId',
    );
    if (lsEnteredAccessCodeForEventID) {
      const enteredAccessCodeForEventId = JSON.parse(
        lsEnteredAccessCodeForEventID,
      );

      lsParams.set(
        'lsEnteredAccessCode',
        enteredAccessCodeForEventId.accessCode,
      );
    }

    const userLang = localStorage.getItem('userLang');
    if (userLang) {
      lsParams.set('userLang', userLang);
    }
    this.getEventByUri(eventUri, lsParams);
  }
}
