<div class="attendee">
  <div class="user-avatar-name">
    <app-user-avatar
      [user]="attendee?.user"
      [size]="35"
      [placeholderFontSize]="15"
      [fallbackInitials]="attendee?.getFallbackInitials()"
    ></app-user-avatar>
    <div class="attendee-name body-font">
      @if (showGuestName || isLoggedUserHost) {
        {{ attendee?.getName() || attendee?.getEmail() }}
        @if (attendee?.groupName && attendee?.groupName !== "") {
          ({{ attendee?.groupName }})
        }
      }
      @if (isAdmin) {
        @if (attendee?.userFull?.email && attendee?.userFull?.email !== "") {
          ({{ attendee?.userFull?.email }})
        } @else if (
          attendee?.userFull?.phone && attendee?.userFull?.phone !== ""
        ) {
          ({{ attendee?.userFull?.phone }})
        }
      }
    </div>
  </div>
  <div class="btns-wrapper">
    <button
      *ngIf="
        isLoggedUserHost &&
        (attendeeRSVPOption == RSVPOptionType.NO || onWaitList) &&
        attendee?.message &&
        attendee?.message != ''
      "
      class="not-going-msg-btn"
      (click)="openNotAttendeeMsg()"
    >
      <i class="fal fa-envelope"></i>
    </button>
    @if (!attendeeOnWaitlist || pendingAttendee) {
      <form [formGroup]="form" *ngIf="isLoggedUserHost">
        <mat-form-field class="flex-input" appearance="outline"
                        class="table-select">
          <div class="column-elements">
            <mat-select formControlName="rsvpOptionType">
              <mat-option
                *ngFor="let rsvpType of rsvpTypes"
                [value]="rsvpType"
                (click)="selectRSVPOption(rsvpType)"
              >
                {{ getDisplayRSVPOptionName(rsvpType) | translate }}
              </mat-option>
            </mat-select>
            @if (attendee){
              @if(attendee.rsvpDate){
                <span class="date" appTextSize [size]='"x-small"'>{{ attendee.rsvpDate | timeAgo }}</span>
              }
            }
          </div>


        </mat-form-field>
      </form>
    }
  </div>
</div>
