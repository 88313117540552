import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { CreateOrUpdateEventEmailRequest } from '@models/events/dto/create-or-update-event-email.request';
import { EventSectionUpdateRequest } from '@models/events/dto/event-update.request';
import { UpdateEventRSVPConfirmationPageSectionRequest } from '@models/events/dto/update-event-rsvp-confirmation-page-section.request';
import { EventEmail } from '@models/events/event-email.model';
import { EventRSVPConfirmationPageSection } from '@models/events/event-rsvp-confirmation-page-section.model';
import { User } from '@models/users/user.model';
import { YesNoDialog } from '@modules/customer/dialogs/yes-no/yes-no.dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogRef,
} from '@angular/material/dialog';
import { SearchBoxComponent } from '@modules/shared/components/search-box/search-box.component';
import { SharedService } from '@services/shared/shared.service';
import { GiphyResponse } from '@models/shared/giphy-response.model';
import { UnsplashResponse } from '@models/shared/unsplash-response.model';
import { Asset, createDataUrl, emptyAsset } from '@models/shared/asset.model';
import { EventService } from '@services/events/event.service';
import { EventLinkBioService } from '@services/event-link-bio/event-link-bio.service';
import { EventLinkBio } from '@models/event-link-bio/event-link-bio.model';
import { finalize } from 'rxjs';
import { AppRoutes } from '../../../../routes';

@Component({
  selector: 'app-image-picker',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    TranslateModule,
    SearchBoxComponent,
    MatDialogActions,
  ],
  providers: [SharedService, EventService, EventLinkBioService],
  templateUrl: './image-picker.dialog.html',
  styleUrl: './image-picker.dialog.scss',
})
export class ImagePickerDialog {
  event?: any;
  eventImagePicker = false;
  selectedTabIndex = 0;
  eventLinkBio?: EventLinkBio;
  showGif: boolean = true;

  giphyRes?: GiphyResponse;
  unsplashRes?: UnsplashResponse;

  selectedImage?: Asset;

  imageTags = [
    'APP.IMAGE_TAGS.TRENDING',
    'APP.IMAGE_TAGS.BIRTHDAY',
    'APP.IMAGE_TAGS.WG_PARTY',
  ];
  selectedTag?: string;

  customImageAcceptedTypes = 'image/png,image/jpeg,image/gif,video/mp4';

  uploadInProgress = false;

  section?: EventRSVPConfirmationPageSection;
  eventSection?: EventSectionUpdateRequest;
  eventEmail?: EventEmail;
  loggedUser?: User;

  private readonly base64Prefix = 'base64,';

  constructor(
    public dialogRef: MatDialogRef<ImagePickerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sharedService: SharedService,
    private translateService: TranslateService,
    private eventService: EventService,
    private eventLinkBioService: EventLinkBioService,
    private http: HttpClient,
    private dialog: MatDialog,
    private router: Router,
  ) {
    if (data) {
      if (data.event) {
        this.event = data.event;
      }

      if (data.section) {
        this.section = data.section;
        this.showGif = false;
      }

      if (data.eventLinkBio) {
        this.eventLinkBio = data.eventLinkBio;
        this.showGif = false;
      }

      if (data.eventEmail) {
        this.eventEmail = data.eventEmail;
        this.showGif = false;
      }

      if (data.eventSection) {
        this.eventSection = data.eventSection;
        this.showGif = false;
      }

      this.eventImagePicker = data.eventImagePicker;

      if (data.gifsPreselected) {
        this.selectedTabIndex = 1;
      }

      if (data.loggedUser) {
        this.loggedUser = data.loggedUser;
      }
    }

    this.onSearchTermChange('');
  }

  onSearchTermChange(searchTerm: string, isTag = false) {
    this.sharedService.getGiphyGifs(searchTerm).subscribe({
      next: (giphyRes) => {
        this.giphyRes = giphyRes;
      },
    });
    this.sharedService.getUnsplashImages(searchTerm).subscribe({
      next: (unsplashRes) => {
        this.unsplashRes = unsplashRes;
      },
    });

    if (!isTag && this.selectedTag) {
      this.selectedTag = undefined;
    }
  }

  toggleTag(tag: string) {
    if (this.selectedTag === tag) {
      this.selectedTag = undefined;
      this.onSearchTermChange('');
      return;
    }

    this.selectedTag = tag;
    this.onSearchTermChange(
      this.translateService.instant(this.selectedTag),
      true,
    );
  }

  uploadImage(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    this.handleFileChange(files[0]);
  }

  async selectUnsplashImage(imageUrl: string) {
    if (!this.eventImagePicker) {
      this.dialogRef.close({ imageUrl: imageUrl });
      return;
    }

    if (this.event) {
      if (this.section) {
        const request: UpdateEventRSVPConfirmationPageSectionRequest = {
          backgroundExternalUrl: imageUrl,
          backgroundColor: undefined,
        };
        this.eventService
          .updateEventRSVPConfirmationPageSection(
            this.event.id,
            this.section.id,
            request,
          )
          .subscribe({
            next: () => {
              //Todo update page
            },
          });
        this.dialogRef.close();
      } else if (this.eventEmail) {
        const request: CreateOrUpdateEventEmailRequest = {
          emailType: this.eventEmail.type,
          backgroundExternalUrl: imageUrl,
        };
        this.eventService.updateEventEmail(this.event.id, request).subscribe({
          next: (res) => {
            this.eventEmail = res;
            this.dialogRef.close({ eventEmail: res });
          },
        });
      } else if (this.eventSection) {
        const updateEventSection: EventSectionUpdateRequest = {
          ...this.eventSection,
          imageExternalUrl: imageUrl,
        };

        this.eventService
          .updateEvent(this.event?.id, { section: updateEventSection })
          .pipe(finalize(() => (this.uploadInProgress = false)))
          .subscribe((event) => {
            this.event = event;
            this.dialogRef.close({
              event: this.event,
              imageUrl: imageUrl,
            });
          });
      } else {
        this.eventService
          .updateEvent(this.event?.id, {
            externalUrl: imageUrl,
            newUser: {
              eventUuid: this.event?.uuid,
            },
          })
          .subscribe((event) => (this.event = event));
        this.dialogRef.close({
          event: this.event,
          imageUrl: imageUrl,
        });
      }
    }

    if (this.eventLinkBio) {
      this.eventLinkBioService
        .update(this.eventLinkBio.id, {
          customBackgroundExternalUrl: imageUrl,
          removeBackground: true,
        })
        .subscribe((eventLinkBio) => {
          this.eventLinkBio = eventLinkBio;
        });
      this.dialogRef.close({
        eventLinkBio: this.eventLinkBio,
        imageUrl: imageUrl,
      });
    }
  }

  selectGif(gifUrl: string) {
    if (!this.eventImagePicker) {
      this.dialogRef.close({ imageUrl: gifUrl });
      return;
    }

    this.eventService
      .updateEvent(this.event?.id, {
        externalUrl: gifUrl,
        newUser: {
          eventUuid: this.event?.uuid,
        },
      })
      .subscribe((event) => (this.event = event));

    this.dialogRef.close({ event: this.event, imageUrl: gifUrl });
  }

  updateToProDialog() {
    const dialogRef = this.dialog.open(YesNoDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        title: 'APP.PRO_FEATURES.UPGRADE_TO_PRO',
        message: 'APP.PRO_FEATURES.THIS_IS_PRO_FEATURE',
        yesLabel: 'APP.PRO_FEATURES.UPGRADE_TO_PRO',
        noLabel: 'APP.PRO_FEATURES.NOT_NOW',
      },
      panelClass: ['normal-dialog'],
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.router.navigate(['/', AppRoutes.Customer.settings], {
          queryParams: {
            tab: 'plan',
          },
        });
        this.dialogRef.close();
      }
    });
  }

  private handleFileChange(file: File) {
    if (!file) {
      this.selectedImage = undefined;
      return;
    }

    if (
      file.type === 'video/mp4' &&
      !this.loggedUser?.isPro() &&
      !this.event?.isPro
    ) {
      this.updateToProDialog();
      return;
    }

    if (!this.selectedImage) {
      this.selectedImage = emptyAsset();
    }
    this.selectedImage.name = file ? file.name : '';

    if (file && file.type) {
      this.selectedImage.mimeType = file.type;

      if (
        !this.customImageAcceptedTypes
          .split(',')
          .includes(this.selectedImage.mimeType)
      ) {
        return;
      }
    }

    this.loadImage(file);
  }

  async convertToAsset(
    imageUrl: string,
    title?: string,
    alt?: string,
  ): Promise<Asset> {
    // Fetch image as Blob
    const imageBlob = await this.http
      .get(imageUrl, { responseType: 'blob' })
      .toPromise();

    // Convert Blob to base64
    const base64String = await this.blobToBase64(imageBlob);

    // Create an Asset object
    const asset: Asset = {
      name: 'party-photo',
      mimeType: imageBlob?.type,
      path: imageUrl,
      base64: base64String,
      title: title || undefined,
      alt: alt || undefined,
    };

    return asset;
  }

  private blobToBase64(blob: Blob | undefined): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      if (blob) reader.readAsDataURL(blob);
    });
  }
  private loadImage(file: File) {
    this.getBase64(file).then((base64: string | ArrayBuffer | null) => {
      if (!base64 || !this.selectedImage) {
        return;
      }

      base64 = base64.toString();
      base64 = base64.substring(
        base64.indexOf(this.base64Prefix) + this.base64Prefix.length,
      );

      this.selectedImage.base64 = base64;

      if (!this.eventImagePicker) {
        this.dialogRef.close({
          imageAsset: this.selectedImage,
        });
        return;
      }

      if (this.event) {
        if (this.section) {
          this.uploadInProgress = true;
          const request: UpdateEventRSVPConfirmationPageSectionRequest = {
            backgroundAsset: this.selectedImage,
            backgroundColor: undefined,
          };
          this.eventService
            .updateEventRSVPConfirmationPageSection(
              this.event?.id,
              this.section.id,
              request,
            )
            .pipe(finalize(() => (this.uploadInProgress = false)))
            .subscribe((event) => {
              this.dialogRef.close();
            });
        } else if (this.eventEmail) {
          const request: CreateOrUpdateEventEmailRequest = {
            emailType: this.eventEmail.type,
            backgroundAsset: this.selectedImage,
            backgroundColor: undefined,
          };
          this.eventService
            .updateEventEmail(this.event?.id, request)
            .pipe(finalize(() => (this.uploadInProgress = false)))
            .subscribe((eventEmail) => {
              this.dialogRef.close({ eventEmail: eventEmail });
            });
        } else if (this.eventSection) {
          const updateEventSection = {
            ...this.eventSection,
            imageAsset: this.selectedImage,
          };

          this.eventService
            .updateEvent(this.event?.id, { section: updateEventSection })
            .pipe(finalize(() => (this.uploadInProgress = false)))
            .subscribe((event) => {
              this.event = event;
              this.dialogRef.close({
                event: this.event,
                imageUrl: this.selectedImage,
              });
            });
        } else {
          this.uploadInProgress = true;
          this.eventService
            .updateEvent(this.event?.id, {
              mainPictureAsset: this.selectedImage,
              newUser: {
                eventUuid: this.event?.uuid,
              },
            })
            .pipe(finalize(() => (this.uploadInProgress = false)))
            .subscribe((event) => {
              this.event = event;
              this.dialogRef.close({
                event: this.event,
                imageUrl: this.event.mainPictureUrl,
              });
            });
        }
      }

      if (this.eventLinkBio) {
        this.eventLinkBioService
          .update(this.eventLinkBio.id, {
            customBackgroundAsset: this.selectedImage,
          })
          .subscribe((eventLinkBio) => (this.eventLinkBio = eventLinkBio));
        this.dialogRef.close({
          eventLinkBio: this.eventLinkBio,
          imageUrl: createDataUrl(this.selectedImage),
        });
      }
    });
  }

  private getBase64(file: File) {
    return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  close() {
    this.dialogRef.close();
  }
}
