@if (event) {
  <div class="comments-cont" [class.restricted]="!isUserHostOrAttendee">
    @if (isUserHostOrNonPendingAttendee) {
      <div class="add-comment-cont">
        <app-user-avatar
          [user]="loggedUser"
          [size]="50"
          [placeholderFontSize]="18"
        ></app-user-avatar>
        <div class="comment-message-cont add-comment-message-cont body-font">
          <div class="message-content">
            <div
              text-editable
              class="add-message-input"
              [(ngModel)]="newComment"
              [attr.placeholder]="'APP.COMMENTS.PLACEHOLDER' | translate"
            ></div>
            @if (commentImageAsset) {
              <img
                [src]="createDataUrl(commentImageAsset)"
                class="comment-image"
              />
            } @else if (commentImageUrl) {
              <img [src]="commentImageUrl" class="comment-image" />
            }
          </div>

          <div
            class="comment-actions"
            [class.has-image]="!!commentImageAsset || commentImageUrl"
          >
            <i class="fa fa-camera upload-icon" (click)="openImagePicker()"></i>
            <i class="fa fa-paper-plane send-icon" (click)="addComment()"></i>
          </div>
        </div>
      </div>
    }
    @for (comment of comments; track comment.id) {
      <app-event-comment
        [loggedUser]="loggedUser"
        [event]="event"
        [comment]="comment"
        (addCommentReaction)="
          addCommentReaction($event.emoji, $event.commentId)
        "
        (removeComment)="removeComment($event)"
      ></app-event-comment>
    }
  </div>
}
