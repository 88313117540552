import { Pipe, PipeTransform } from '@angular/core';
import moment from "moment/moment";
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'timeAgo',
  standalone: true,
})
export class TimeAgoPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: string | Date): string {
    if (!value) return '';

    const currentDate = moment();
    const date = moment(value);

    const secondsDiff = currentDate.diff(date, 'seconds');

    if (secondsDiff < 60) {
      return this.translateService.instant(
        'APP.TIME_AGO.FEW_MOMENTS_AGO',
      )
    } else if (secondsDiff < 3600) {
      const minutes = currentDate.diff(date, 'minutes');
      return this.formatAgo( `${minutes}`, 'min')
    } else if (
      secondsDiff < 86400 &&
      date.isSame(currentDate, 'day')
    ) {
      const hours = currentDate.diff(date, 'hours');
      return this.formatAgo( `${hours}`, 'hour')
    } else if (date.isSame(currentDate.clone().subtract(1, 'day'), 'day')) {
      const yesterday = this.translateService.instant('APP.TIME_AGO.YESTERDAY')
      const time = date.format('HH:mm')
      return `${yesterday}, ${time}`
    } else {
      return date.format('DD.MM.YYYY, HH:mm');
    }
  }

  formatAgo(value: string, unit: string){
    let unitType: string = unit === 'min'
      ? this.translateService.instant('APP.TIME_AGO.MIN')
      : this.translateService.instant('APP.TIME_AGO.HOUR');

    const prefix = this.translateService.instant('APP.TIME_AGO.AGO_PREFIX');
    const suffix = this.translateService.instant('APP.TIME_AGO.AGO_SUFFIX');

    return `${prefix} ${value} ${unitType} ${suffix}`
  }
}
