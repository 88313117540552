import { ClassData } from '@models/class-data';
import { User } from '@models/users/user.model';

export class EventCommentReaction {
  id: number;
  createdAt?: Date;

  eventCommentId: number;
  userId: number;
  user?: User;

  emoji: string;

  constructor(json: ClassData<EventCommentReaction>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.eventCommentId = +json.eventCommentId;
    this.userId = +json.userId;

    if (json.user) {
      this.user = new User(json.user);
    }

    this.emoji = json.emoji;
  }
}

export class EventCommentReactionUsersInfo {
  id: number;
  createdAt?: Date;

  eventCommentId: number;

  emoji: string;

  users?: User[];

  constructor(json: ClassData<EventCommentReactionUsersInfo>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.eventCommentId = +json.eventCommentId;

    this.emoji = json.emoji;

    if (json.users) {
      this.users = json.users;
    }
  }

  getNumUsers(): number {
    return this.users?.length || 0;
  }

  isLoggedUserReaction(loggedUserId?: number): boolean {
    return (
      !!loggedUserId && !!this.users?.find((user) => user.id === loggedUserId)
    );
  }

  getUserNames(): string {
    if (!this.users) {
      return '';
    }

    if (this.users.length > 3) {
      return this.users
        .slice(0, 3)
        .map((user) => user.name)
        .join(', ');
    }

    return this.users.map((user) => user.name).join(', ');
  }

  getNumOthers(): number {
    const numUsers = this.getNumUsers();
    if (numUsers > 3) {
      return numUsers - 3;
    }
    return 0;
  }
}
