<div class="image-picker-dialog event-dialog-content" mat-dialog-content>
  <div (click)="close()" class="close-button">
    <i class="fal fa-times-circle"></i>
  </div>
  <div class="dialog-body">
    <div class="dialog-title">{{ "APP.UPLOAD_A_PIC" | translate }}</div>

    <app-search-box
      placeholder="APP.SEARCH_IMAGES"
      [debounceTime]="250"
      (valueChanged)="onSearchTermChange($event)"
    ></app-search-box>

    <div class="image-tags">
      @for (tag of imageTags; track tag) {
        <div
          [class.active]="selectedTag === tag"
          class="tag"
          (click)="toggleTag(tag)"
        >
          <div class="tag-text">{{ tag | translate }}</div>
        </div>
      }
    </div>

    <mat-tab-group
      mat-stretch-tabs="false"
      class="image-picker-tabs"
      [selectedIndex]="selectedTabIndex"
    >
      <mat-tab label="{{ 'APP.IMAGES' | translate }}">
        <div class="image-list">
          @if (unsplashRes) {
            @for (image of unsplashRes.results; track image.id) {
              <div class="unsplash-image">
                <img
                  [src]="image.urls.thumb"
                  alt="Image Thumbnail"
                  class="image"
                />
                <div class="middle">
                  <button
                    type="button"
                    class="btn btn-primary"
                    (click)="selectUnsplashImage(image.urls.small)"
                  >
                    {{ "APP.SELECT" | translate }}
                  </button>
                </div>
                <div class="unsplash-user">
                  by
                  <a
                    href="{{
                      image.user.links.html
                    }}?utm_source=eventpage.ai&utm_medium=referral"
                    target="_blank"
                    >{{ image.user.name }}</a
                  >
                </div>
              </div>
            }
          }
        </div>
        <div class="attribution-div">
          Source:
          <a
            href="https://unsplash.com/?utm_source=eventpage.ai&utm_medium=referral"
            target="_blank"
            >Unsplash</a
          >
        </div>
      </mat-tab>
      @if (showGif) {
        <mat-tab label="{{ 'APP.GIFS' | translate }}">
          <div class="image-list">
            @if (giphyRes) {
              @for (gif of giphyRes.data; track gif.id) {
                <div class="giphy-gif">
                  <img
                    [src]="gif.images.original.url"
                    alt="GIF Thumbnail"
                    class="image"
                  />
                  <div class="middle">
                    <button
                      type="button"
                      class="btn btn-primary"
                      (click)="selectGif(gif.images.original.url)"
                    >
                      {{ "APP.SELECT" | translate }}
                    </button>
                  </div>
                </div>
              }
            }
          </div>
          <img
            class="attribution-img"
            src="/assets/giphy/powered_by.png"
            alt="Powered by GIPHY"
          />
        </mat-tab>
      }
    </mat-tab-group>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <input
      hidden
      type="file"
      [accept]="customImageAcceptedTypes"
      #uploader
      (change)="uploadImage($event)"
    />
    <button
      type="button"
      class="btn btn-primary"
      [class.spinner]="uploadInProgress"
      [disabled]="uploadInProgress"
      (click)="uploader.click()"
    >
      {{ "APP.UPLOAD_IMAGE" | translate }}
    </button>
  </div>
</div>
