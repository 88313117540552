@if (event && form) {
<div
  class="add-host-dialog event-dialog-content"
  mat-dialog-content
  [formGroup]="form"
>
  <div (click)="close()" class="close-button">
    <i class="fal fa-times-circle"></i>
  </div>
  <div class="dialog-body">
    <div class="dialog-title">{{ "APP.EVENT_EDIT.ADD_HOST" | translate }}</div>
    <div>
      <div class="message">
        {{ "APP.EVENT_EDIT.ADD_HOST_TEXT" | translate }}
      </div>
      <mat-form-field appearance="outline" class="w-100 email-input">
        <mat-label>{{ "APP.EMAIL" | translate }}</mat-label>
        <input type="text" matInput formControlName="email" />

        @if (form.controls['email'].hasError('custom')) {
          <mat-error>
            {{ form.controls["email"].getError("custom") }}
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <div class="btn btn-primary" (click)="invite()">
      {{ "APP.INVITE" | translate }}
    </div>
  </div>
</div>
}
