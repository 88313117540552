import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'app-restricted-access',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './restricted-access.component.html',
  styleUrl: './restricted-access.component.scss',
})
export class RestrictedAccessComponent {
  // @Input() event?: Event;
  @Output() rsvpChange = new EventEmitter();

  constructor() {}

  onRsvp() {
    this.rsvpChange.emit();
  }

  /*   onRsvp() {
    if (this.event) {
      const dialogRef = this.dialog.open(RsvpDialog, {
        maxWidth: '902px',
        maxHeight: '100vh',
        width: '100%',
        height: 'auto',
        data: {
          event: this.event,
          rsvpType: RSVPOptionType.YES,
          isAttendee: false,
        },
        panelClass: ['normal-dialog', 'overlay-theme'],
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res && this.event) {
          //this.eventStore.refreshEvent(this.event.uri);
          this.dialog.open(EventAfterAttendOverlayDialogComponent, {
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            panelClass: 'overlay-page-dialog',
            data: this.event,
          });
        }
      });
    }
  } */
}
