export enum SocialMediaType {
  INSTAGRAM = 'INSTAGRAM',
  FACEBOOK = 'FACEBOOK',
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
  LINKEDIN = 'LINKEDIN',
  CUSTOM = 'LINK',
}

export namespace SocialMediaType {
  export function getSocialMediaTypes(): SocialMediaType[] {
    return [
      SocialMediaType.INSTAGRAM,
      SocialMediaType.FACEBOOK,
      SocialMediaType.TIKTOK,
      SocialMediaType.YOUTUBE,
      SocialMediaType.LINKEDIN,
      SocialMediaType.CUSTOM,
    ];
  }
}
