import { ClassData } from '@models/class-data';
import { EventTemplateSeo } from '@models/design-templates/event-template-seo.model';
import { EventTemplate } from '@models/design-templates/event-template.model';
import { EventlinkBioStatus } from '@models/event-link-bio/event-link-bio-status.enum';
import { EventTemplateAsset } from '@models/events/event-template-asset.model';
import { User } from '@models/users/user.model';
import { Helpers } from '@utils/helpers';
import { environment as env } from '@environments/environment';

export class EventLinkBio {
  id: number;
  uri?: string;
  userId: number;
  user?: User;
  title?: string;
  description?: string;
  backgroundId?: number;
  background?: EventTemplateAsset;
  backgroundColor?: string;
  customBackgroundUrl?: string;
  titleColor?: string;
  bodyColor?: string;
  effectId?: number;
  effect?: EventTemplateAsset;
  status?: EventlinkBioStatus;
  seo?: EventTemplateSeo;
  seoId?: number;

  constructor(json: ClassData<EventLinkBio>) {
    this.id = +json.id;
    this.userId = +json.userId;
    if (json.user) {
      this.user = new User(json.user);
    }
    this.title = json.title;
    this.description = json.description;
    this.uri = json.uri;
    if (json.backgroundId !== undefined) {
      this.backgroundId = +json.backgroundId;
    }
    if (json.background) {
      this.background = new EventTemplateAsset(json.background);
    }

    if (this.seoId) {
      this.seoId = json.seoId;
    }

    if (json.seo) {
      this.seo = new EventTemplateSeo(json.seo);
    }
    this.backgroundColor = json.backgroundColor;
    this.customBackgroundUrl = json.customBackgroundUrl;
    this.titleColor = json.titleColor;
    this.bodyColor = json.bodyColor;
    if (json.effectId !== undefined) {
      this.effectId = +json.effectId;
    }
    if (json.effect) {
      this.effect = new EventTemplateAsset(json.effect);
    }
    this.status = json.status;
  }

  getLink(): string {
    return `${env.website}/eventlink.bio/${this.uri}`;
  }

  getDomainLink(): string {
    return `${env.website}/eventlink.bio/`;
  }

  isBackgroundImage(): boolean {
    return (
      !!this.background && Helpers.isImageMimeType(this.background.mimeType)
    );
  }

  isBackgroundVideo(): boolean {
    return (
      !!this.background && Helpers.isVideoMimeType(this.background.mimeType)
    );
  }

  getBackgroundImage() {
    if (this.background?.url && this.isBackgroundImage()) {
      return this.background.url;
    } else {
      return this.customBackgroundUrl;
    }
  }
  isEffectImage(): boolean {
    return !!this.effect && Helpers.isImageMimeType(this.effect.mimeType);
  }
}
