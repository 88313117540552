import {
  booleanAttribute,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { UserAvatarComponent } from '@modules/shared/components/user-avatar/user-avatar.component';
import { RSVPOptionType } from '@models/event-attendance/rsvp-option-type.enum';
import { EventAttendee } from '@models/event-attendance/event-attendee.model';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { FormsModules } from '@utils/shared-modules';
import { EventAttendeeStatus } from '@models/event-attendance/event-attendee-status.enum';
import { MatMenuItem } from '@angular/material/menu';
import { TextSizeDirective } from '../../../../../directives/text-size.directive';
import { TimeAgoPipe } from '../../../../../pipes/time-ago.pipe';

@Component({
  selector: 'app-attendee-row',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    MatOptionModule,
    TranslateModule,
    UserAvatarComponent,
    MatMenuItem,
    TimeAgoPipe,
    TextSizeDirective,
  ],
  templateUrl: './attendee-row.component.html',
  styleUrl: './attendee-row.component.scss',
})
export class AttendeeRowComponent implements OnInit {
  private _attendee?: EventAttendee;
  _attendeeRSVPOption?: RSVPOptionType;

  @Input() set attendee(attendee: EventAttendee) {
    this._attendee = attendee;
    this._attendeeRSVPOption =
      attendee.status === EventAttendeeStatus.PENDING_APPROVAL
        ? RSVPOptionType.PENDING_APPROVAL
        : attendee.rsvpType;
  }
  @Input() font?: EventTemplateFont;
  @Input() rsvpTypes?: RSVPOptionType[];
  @Input({ transform: booleanAttribute }) isLoggedUserHost: boolean = true;
  @Input({ transform: booleanAttribute }) isAdmin = false;
  @Input() showGuestName?: boolean;
  @Input() onWaitList?: boolean;

  @Output() selectedRSVPOption = new EventEmitter<RSVPOptionType>();
  @Output() displayMsgForNotAttendingUser = new EventEmitter();
  @Output() pendingChange = new EventEmitter<RSVPOptionType>();

  form!: FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.form = new FormGroup({
      rsvpOptionType: new FormControl(this._attendeeRSVPOption || ''),
    });
  }

  get attendeeRSVPOption(): RSVPOptionType | undefined {
    return this._attendeeRSVPOption;
  }

  get attendee(): EventAttendee | undefined {
    return this._attendee;
  }

  get attendeeOnWaitlist(): boolean {
    return this.attendee?.status === EventAttendeeStatus.ON_WAITLIST;
  }

  get pendingAttendee(): boolean {
    return this.attendee?.status === EventAttendeeStatus.PENDING_APPROVAL;
  }

  getDisplayRSVPOptionName(status: RSVPOptionType): string {
    return RSVPOptionType.getDisplayName(status);
  }

  selectRSVPOption(rsvpOptionType: RSVPOptionType): void {
    const isPending =
      this._attendeeRSVPOption === RSVPOptionType.PENDING_APPROVAL;
    this._attendeeRSVPOption = rsvpOptionType;

    if (isPending) {
      this.pendingChange.emit(rsvpOptionType);
    } else {
      this.selectedRSVPOption.emit(rsvpOptionType);
    }
  }
  openNotAttendeeMsg() {
    this.displayMsgForNotAttendingUser.emit();
  }

  protected readonly RSVPOptionType = RSVPOptionType;
}
