@if (campaignData) {
  @if (isVotingOver && winners) {
    @if (isWinner && winners.ranking > 0) {
      <div class="campaign-winners">
        <div class="ranking-wrapper">
          <div class="title">
            <div class="campaign-data">
              <i class="fas fa-trophy"></i>
              @if (winners.title) {
                <h3>{{ winners.title | translate }}</h3>
              }
            </div>
          </div>
          <app-campaign-ranking-card
            [votes]="winners.votes"
            [ranking]="winners.ranking"
          />
        </div>
      </div>
    }

    @if (
      isLoggedUserHost &&
      event?.eventCampaign?.category ===
        EventCampaignCategory.MOST_CREATIVE_CONCEPT &&
      (!isWinner || (winners.ranking === 0 && event?.isPublished()))
    ) {
      <div class="loser-wrapper">
        <div class="ranking-wrapper loser">
          <div class="title">
            <div class="campaign-data">
              <h3>{{ "APP.VOTING.RATING.WINNER_OF_HEARTS" | translate }}</h3>
            </div>
          </div>
          <app-campaign-ranking-card
            [votes]="campaignData.votes"
            [ranking]="campaignData.ranking"
          />
        </div>
      </div>
    }
  } @else if (!isVotingOver) {
    <div
      class="ranking-wrapper"
      (click)="isLoggedUserHost ? null : goVote()"
      [class.click-enabled]="!isLoggedUserHost"
    >
      <div class="title">
        @if (isLoggedUserHost) {
          <div class="campaign-host-data">
            <h3>{{ "APP.VOTING.RATING.COLLECT_VOTES" | translate }}</h3>
            <div (click)="copyLink()" class="action">
              <i class="far fa-copy"></i>
            </div>
            <div class="action" (click)="share()">
              <i class="fas fa-share"></i>
            </div>
          </div>
        } @else {
          <div class="campaign-data">
            <h3>{{ "APP.VOTING.RATING.VOTE_AND_WIN" | translate }}</h3>
            <i class="far fa-long-arrow-right"></i>
          </div>
        }
      </div>
      <app-campaign-ranking-card
        [votes]="campaignData.votes"
        [ranking]="campaignData.ranking"
      />
    </div>
  }
}
