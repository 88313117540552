<div class="share-buttons"
     [class.coloured]='iconsColor === RSVPConfirmationPageSectionIconsColorType.COLORED'
     [class.dark]='iconsColor === RSVPConfirmationPageSectionIconsColorType.DARK'
     [class.light]='iconsColor === RSVPConfirmationPageSectionIconsColorType.LIGHT'
     [class.theme]='iconsColor === RSVPConfirmationPageSectionIconsColorType.THEME'
>
  @for(option of socialMediaTypes; track $index){
    <i class="fa{{option === SocialMediaType.CUSTOM ? 'r' : 'b'}} fa-{{ option.toLowerCase() }} share-icon"
       [ngStyle]="{
      'color': getThemeIconColors(),
    }"
       [ngClass]="iconsColor === RSVPConfirmationPageSectionIconsColorType.COLORED ? option.toLowerCase() : null"
       [class.disabled]="missingLink(option)"
       [class.hidden]='missingLink(option) && !isEditMode'
       [class.clickable]='!isEditMode'
       (click)='copyLink(option)'
    ></i>
  }
</div>
