import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-campaign-ranking-card',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './campaign-ranking-card.component.html',
  styleUrl: './campaign-ranking-card.component.scss',
})
export class CampaignRankingCardComponent {
  @Input() votes?: number;
  @Input() ranking?: number;
}
