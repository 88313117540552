@if (event && comment) {
  <div
    class="comment-cont"
    [class.my-comment]="loggedUser?.id === comment.userId"
  >
    <app-user-avatar
      [user]="comment.user"
      [size]="50"
      [placeholderFontSize]="18"
    ></app-user-avatar>
    <div class="comment-message-cont body-font">
      @if (loggedUser?.id !== comment.userId) {
        <div class="comment-user">
          <div class="user-name">{{ comment.user?.name }}</div>
          @if (event.isUserHost(comment.userId)) {
            <div class="user-host">
              <i class="fal fa-crown"></i>
              {{ "APP.COMMENTS.HOST" | translate }}
            </div>
          }
        </div>
      }

      <div [innerText]="comment.comment"></div>

      @if (comment.assets && comment.assets.length > 0) {
        @for (asset of comment.assets; track asset.id) {
          <img [src]="asset.url" class="comment-image" />
        }
      }

      @if (comment.reactions && comment.reactions.length > 0) {
        <div class="comment-reactions">
          @for (
            reactionInfo of comment.getEventCommentReactionUsersInfo();
            track $index
          ) {
            <div
              class="comment-reaction"
              [class.my-reaction]="
                reactionInfo.isLoggedUserReaction(loggedUser?.id)
              "
              (click)="onAddCommentReaction(reactionInfo.emoji, comment.id)"
              [ngxTippy]="tooltipReactionTemplate"
              [tippyContext]="{
                data: {
                  emoji: reactionInfo.emoji,
                  userNames: reactionInfo.getUserNames(),
                  numOthers: reactionInfo.getNumOthers()
                }
              }"
              [tippyProps]="{
                interactive: true,
                placement: 'top-start'
              }"
            >
              <div class="reaction-emoji">{{ reactionInfo.emoji }}</div>
              <div class="reaction-num">{{ reactionInfo.getNumUsers() }}</div>
            </div>
          }
        </div>

        <ng-template #tooltipReactionTemplate let-data="data">
          <div class="reacted-by">
            {{ data.emoji }}
            {{ "APP.COMMENTS.REACTED_BY" | translate }}
            {{ data.userNames }}
            @if (data.numOthers > 0) {
              <span
                >{{ "APP.AND" | translate }} {{ data.numOthers }}
                {{ "APP.COMMENTS.OTHERS" | translate }}</span
              >
            }
          </div>
        </ng-template>
      }

      <div class="message-time">{{ comment.getCommentDisplayTime() }}</div>

      @if (
        event.isUserHost(loggedUser?.id) || loggedUser?.id === comment.userId
      ) {
        <i
          class="fa fa-ellipsis-h edit-icon"
          [matMenuTriggerFor]="appMenu"
          [matMenuTriggerData]="{ comment: comment }"
        ></i>
      }
    </div>
    @if (loggedUser?.id !== comment.userId && isUserHostOrAttendee) {
      <i
        class="fa fa-smile react-icon"
        [ngxTippy]="tooltipEmojiTemplate"
        [tippyProps]="{
          trigger: 'click',
          interactive: true,
          arrow: false,
          placement: 'top-end',
          theme: 'emoji-picker-tippy',
          popperOptions: {
            strategy: 'fixed',
            modifiers: [
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['top', 'bottom-end']
                }
              },
              {
                name: 'preventOverflow',
                options: {
                  altAxis: true,
                  tether: false
                }
              }
            ]
          }
        }"
      ></i>
    }

    <ng-template #tooltipEmojiTemplate>
      <emoji-mart
        id="emoji-palette"
        class="emoji-picker"
        title="{{ 'APP.EVENT_EDIT.PICK_YOUR_EMOJI' | translate }}"
        [showPreview]="false"
        [perLine]="8"
        (emojiClick)="addCommentReactionFromPicker($event, comment.id)"
      ></emoji-mart>
    </ng-template>

    <mat-menu
      #appMenu="matMenu"
      class="mat-menu-actions-container mat-menu-actions-blur-container mat-menu-comment-edit-container"
    >
      <ng-template matMenuContent let-comment="comment">
        <button mat-menu-item (click)="onRemoveComment(comment.id)">
          <span class="menu-item-content delete-item">
            <i class="far fa-trash"></i>
            <span class="text">{{ "APP.COMMENTS.REMOVE" | translate }}</span>
          </span>
        </button>
      </ng-template>
    </mat-menu>
  </div>
}
