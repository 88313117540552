import { Component, Input } from '@angular/core';
import { Event } from '@models/events/event.model';
import { InviteEmailComponent } from '@modules/events/dialogs/invite/invite-email/invite-email.component';
import { InviteShareOptionsComponent } from '@modules/events/dialogs/invite/invite-share-options/invite-share-options.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-invite-friends',
  standalone: true,
  imports: [InviteEmailComponent, InviteShareOptionsComponent, TranslateModule],
  templateUrl: './invite-friends.component.html',
  styleUrl: './invite-friends.component.scss',
})
export class InviteFriendsComponent {
  @Input() event?: Event | undefined;
}
