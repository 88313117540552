<div class="blur-effect">
  <div class='restricted-content'>
    <i class="fas fa-lock-alt lock-icon"></i>
    <h1>{{ "APP.RESTRICTED_ACCESS.RESTRICTED_ACCESS" | translate }}</h1>
    <span> {{ "APP.RESTRICTED_ACCESS.RESTRICTED_DESCRIPTION" | translate }} </span>
  </div>
  <button type="button" class="btn btn-primary btn-rsvp" (click)='onRsvp()'>
    {{ "APP.RESTRICTED_ACCESS.RESTRICTED_BUTTON" | translate }}
  </button>
</div>
