import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule, isPlatformServer } from '@angular/common';
import { EventAttendeeStatus } from '@models/event-attendance/event-attendee-status.enum';
import { RSVPOptionType } from '@models/event-attendance/rsvp-option-type.enum';
import { EventInfoComponent } from '@modules/events/components/event-info/event-info.component';
import { EventAfterAttendOverlayDialogComponent } from '@modules/events/dialogs/event-after-attend-overlay-dialog/event-after-attend-overlay-dialog.component';
import { RsvpDialog } from '@modules/events/dialogs/rsvp/rsvp.dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { EventHostsComponent } from '../event-hosts/event-hosts.component';
import { EventRsvpComponent } from '../event-rsvp/event-rsvp.component';
import { ShareButtonsComponent } from '@modules/shared/components/share-buttons/share-buttons.component';
import { FooterComponent } from 'app/components/footer/footer.component';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { Event } from '@models/events/event.model';
import { FontService } from '@services/shared/font.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { User } from '@models/users/user.model';
import { AddToCalendarDialog } from '@modules/shared/dialogs/add-to-calendar/add-to-calendar.dialog';
import { MatDialog } from '@angular/material/dialog';
import { EventService } from '@services/events/event.service';
import { EventStore } from '@services/stores/event.store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Helpers } from '@utils/helpers';
import { EventCommentsComponent } from '../event-comments/event-comments.component';
import { MatTabsModule } from '@angular/material/tabs';
import { EventWidget } from '@models/events/event-widget.enum';
import { CampaignEventVoteRequest } from '@models/events/dto/campaign-event-vote.request';
import { CampaignEventRankingsResponse } from '@models/campaigns/dto/event-campaign-rating.response';
import { CampaignRankingComponent } from '@modules/events/components/campaign-ranking/campaign-ranking.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-event',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatTabsModule,
    EventHostsComponent,
    EventRsvpComponent,
    ShareButtonsComponent,
    EventCommentsComponent,
    FooterComponent,
    CampaignRankingComponent,
    EventInfoComponent,
  ],
  providers: [EventService, FontService, ScreenWidthService],
  templateUrl: './event.component.html',
  styleUrl: './event.component.scss',
})
export class EventComponent implements OnInit, OnChanges, AfterViewInit {
  isServer = false;

  private _event?: Event;
  @Input() isVotingView?: boolean = false;

  @Input() set event(event: Event) {
    this._event = event;

    if (this.font) {
      const primaryLogoFont = this.font.primaryLogoFont;
      const bodyFont = this.font.bodyFont;
      const fontFamilies: string[] = [];
      if (primaryLogoFont) {
        fontFamilies.push(primaryLogoFont);
      }
      if (bodyFont) {
        fontFamilies.push(bodyFont);
      }

      this.fontService.updateFonts(fontFamilies);

      Helpers.setEventFonts(
        this.font.primaryLogoFont,
        this.font.bodyFont,
        this.font.bodyFontWeight,
      );
    }
  }

  get event(): Event | undefined {
    return this._event;
  }

  @Input() isHostView = false;

  @Input() loggedUser?: User;

  @Input() isCohostInvitation?: boolean;

  isAfterCreation = false;
  eventActionsSticky = false;
  isDesktop = true;
  isNotAttendingUser?: boolean;
  availableCapacity?: number;
  showMaxCapacity?: boolean;
  isCapacity?: boolean;
  isCampaign?: boolean = false;
  campaignData?: CampaignEventRankingsResponse;

  currentLang?: string;

  hasVideoAutoplayOccurred = false;

  @ViewChild('backgroundVideo', { read: ElementRef, static: false })
  backgroundVideo?: ElementRef;
  @ViewChild('effectVideo', { read: ElementRef, static: false })
  effectVideo?: ElementRef;

  constructor(
    private eventService: EventService,
    private eventStore: EventStore,
    private fontService: FontService,
    private screenWidthService: ScreenWidthService,
    private dialog: MatDialog,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.isServer = isPlatformServer(platformId);

    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event) {
        this.event = event;
        if (event.eventCampaign?.campaignId) {
          this.checkCampaignRatings();
          /*     this.campaignData = {
            ranking: 8,
            votes: 190,
            eventId: this.event.id,
          };
          this.isCampaign = true; */
        }
      }
    });

    this.screenWidthService.isDesktop().subscribe({
      next: (isDesktop: boolean) => {
        this.isDesktop = isDesktop;
      },
    });
    this.screenWidthService.isOver992().subscribe({
      next: (isOver992: boolean) => {
        this.eventActionsSticky = !isOver992;
      },
    });

    this.authService.userSubject.subscribe((user) => {
      if (user.id > 0) {
        this.loggedUser = user;
      }
    });

    this.translateService.onLangChange.subscribe(() => {
      this.currentLang = this.translateService.currentLang;
    });
    this.currentLang = this.translateService.currentLang;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.font && this.isVotingView) {
      Helpers.setEventTheme(
        this.font.primaryLogoFontColor,
        this.font.bodyFontColor,
        this.visual?.backgroundColor,
      );
    }
  }

  ngOnInit(): void {
    this.loggedUser && this.checkIfUserAttending();
    this.calculateMaxCapacity();
    if (this.font && this.isVotingView) {
      Helpers.setEventTheme(
        this.font.primaryLogoFontColor,
        this.font.bodyFontColor,
        this.visual?.backgroundColor,
      );
    }
  }

  ngAfterViewInit(): void {
    if (!this.hasVideoAutoplayOccurred) {
      this.checkBgVideoAutoplay();
      this.checkEffectVideoAutoplay();
    }
  }

  checkBgVideoAutoplay(): void {
    if (
      !this.backgroundVideo ||
      !this.backgroundVideo.nativeElement ||
      !this.backgroundVideo.nativeElement.play
    ) {
      return;
    }

    const promise = this.backgroundVideo.nativeElement.play();
    if (promise) {
      promise.then((_: any) => {
        this.hasVideoAutoplayOccurred = true;
      });
    }
  }

  checkEffectVideoAutoplay(): void {
    if (
      !this.effectVideo ||
      !this.effectVideo.nativeElement ||
      !this.effectVideo.nativeElement.play
    ) {
      return;
    }

    const promise = this.effectVideo.nativeElement.play();
    if (promise) {
      promise.then((_: any) => {
        this.hasVideoAutoplayOccurred = true;
      });
    }
  }

  checkCampaignRatings() {
    if (this.event?.eventCampaign?.campaignId) {
      const request: CampaignEventVoteRequest = {
        campaignId: this.event?.eventCampaign?.campaignId,
        eventId: this.event?.id,
      };
      this.eventService.getCampaignRankings(request).subscribe({
        next: (data) => {
          this.campaignData = data;
          this.isCampaign = true;
        },
      });
    }
  }

  joinWaitlist() {
    const dialogRef = this.dialog.open(RsvpDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        event: this.event,
        rsvpType: RSVPOptionType.YES,
        isAttendee: false,
        onWaitlist: true,
      },
      panelClass: ['normal-dialog', 'rsvp-dialog', 'overlay-theme'],
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && this.event) {
        if (res.refresh) {
          this.eventStore.refreshEvent(this.event.uri);
        }

        if (res.openEventAfterAttendOverlayDialog) {
          this.dialog.open(EventAfterAttendOverlayDialogComponent, {
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            panelClass: 'overlay-page-dialog',
            data: {
              event: this.event,
              rsvpType: RSVPOptionType.YES,
              onWaitlist: true,
            },
          });
        }
      }
    });
  }

  getOnPendingList() {
    const dialogRef = this.dialog.open(RsvpDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        event: this.event,
        rsvpType: RSVPOptionType.YES,
        isAttendee: false,
        onWaitlist: false,
        onPendingList: true,
      },
      panelClass: ['normal-dialog', 'rsvp-dialog', 'overlay-theme'],
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && this.event) {
        if (res.refresh) {
          this.eventStore.refreshEvent(this.event.uri);
        }

        if (res.openEventAfterAttendOverlayDialog) {
          this.dialog.open(EventAfterAttendOverlayDialogComponent, {
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            panelClass: 'overlay-page-dialog',
            data: {
              event: this.event,
              rsvpType: RSVPOptionType.YES,
              onWaitlist: false,
              onPendingList: true,
            },
          });
        }
      } else {
        if (this.event) {
          this.eventStore.refreshEvent(this.event.uri);
        }
      }
    });
  }

  calculateMaxCapacity() {
    if (!this.event?.maxCapacity || this.event.maxCapacity === 0) {
      this.showMaxCapacity = false;
      this.isCapacity = true; // There is no limit
    } else {
      const guestsGoing = this.event.getNumYesAttendees() || 0;
      const availablePlaces = this.event.maxCapacity - guestsGoing;

      if (availablePlaces > 0) {
        this.availableCapacity = availablePlaces;
        this.showMaxCapacity = true;
      } else {
        this.showMaxCapacity = false;
      }

      this.isCapacity = this.showMaxCapacity || guestsGoing === 0;
    }
  }
  checkIfUserAttending() {
    const loggedInUserId = this.loggedUser?.id;
    const eventAttendees = this.event?.attendees?.map((x) => x.user?.id);
    const eventHosts = this.event?.hosts?.map((x) => x.user?.id);

    const isNotHost = this.event?.hostUserId !== loggedInUserId;
    const isNotCoHost = !eventAttendees?.includes(loggedInUserId);
    const isNotAttendee = !eventHosts?.includes(loggedInUserId);

    this.isNotAttendingUser = isNotHost && isNotCoHost && isNotAttendee;
  }

  onRefreshEvent() {
    if (this.event) {
      const params = new Map<string, string>();
      const userLang = localStorage.getItem('userLang');
      if (userLang) {
        params.set('userLang', userLang);
      }
      this.eventService.getByUri(this.event.uri, params).subscribe({
        next: (event) => {
          this.event = event;
        },
      });
    }
  }

  shareEvent() {
    if (this.event) {
      window.navigator.share({
        text: this.event.description,
        title: this.event.name,
        url: this.event.getLink(),
      });
    }
  }

  addToCalendar() {
    this.dialog.open(AddToCalendarDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: this.event,
      panelClass: ['normal-dialog', 'event-dialog', 'overlay-theme'],
    });
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  get visualBackgroundUrl(): string | undefined {
    if (!this.visual?.background) {
      return undefined;
    }

    if (this.isDesktop || this.visual?.background?.mobileUrl === '') {
      return this.visual?.background?.url;
    } else {
      return this.visual?.background?.mobileUrl;
    }
  }

  get visualBackgroundHevcUrl(): string | undefined {
    if (!this.visual?.background) {
      return undefined;
    }

    if (this.isDesktop || this.visual?.background?.h265MobileUrl === '') {
      return this.visual?.background?.h265Url;
    } else {
      return this.visual?.background?.h265MobileUrl;
    }
  }

  get visualEffectUrl(): string | undefined {
    if (!this.visual?.effect) {
      return undefined;
    }

    if (this.isDesktop || this.visual?.effect?.mobileUrl === '') {
      return this.visual?.effect?.url;
    } else {
      return this.visual?.effect?.mobileUrl;
    }
  }

  get visualEffectHevcUrl(): string | undefined {
    if (!this.visual?.effect) {
      return undefined;
    }

    if (this.isDesktop || this.visual?.effect?.h265MobileUrl === '') {
      return this.visual?.effect?.h265Url;
    } else {
      return this.visual?.effect?.h265MobileUrl;
    }
  }

  get font(): EventTemplateFont | undefined {
    return this.event?.eventTemplate?.font;
  }

  get isAttendeeView(): boolean {
    return (
      !!this.loggedUser &&
      !!this.event?.getUserAttendeeRSVPOption(this.loggedUser.id) &&
      this.event?.isNonPendingAttendee(this.loggedUser.id)
    );
  }

  get widgets(): EventWidget[] | undefined {
    return this.event?.eventTemplate?.widgets;
  }

  get isUserHost(): boolean {
    return (
      !!this.loggedUser &&
      !!this.loggedUser.id &&
      !!this.event &&
      (this.event.isUserHost(this.loggedUser.id) ||
        this.event.isNotLoggedUserHost())
    );
  }

  get userOnWaitList(): boolean {
    const foundAttendee = this.event?.attendees?.find(
      (x) => x.userId === this.loggedUser?.id,
    );
    return (
      !!foundAttendee &&
      foundAttendee.status === EventAttendeeStatus.ON_WAITLIST
    );
  }

  get userOnPendingList(): boolean {
    const foundAttendee = this.event?.attendees?.find(
      (x) => x.userId === this.loggedUser?.id,
    );
    return (
      !!foundAttendee &&
      foundAttendee.status === EventAttendeeStatus.PENDING_APPROVAL
    );
  }

  @HostListener('document:touchstart', ['$event'])
  onTouchStart(event: Event): void {
    if (!this.hasVideoAutoplayOccurred) {
      this.hasVideoAutoplayOccurred = true;
      this.checkBgVideoAutoplay();
      this.checkEffectVideoAutoplay();
    }
  }

  protected readonly EventWidget = EventWidget;
}
