import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Event } from '@models/events/event.model';
import { TranslateModule } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { EventInviteRequest } from '@models/events/dto/event-invite.request';

@Component({
  selector: 'app-invite-email',
  standalone: true,
  imports: [
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule,
    MatChipsModule,
    MatIconModule,
  ],
  providers: [EventService],
  templateUrl: './invite-email.component.html',
  styleUrl: './invite-email.component.scss',
})
export class InviteEmailComponent {
  @Input() event?: Event;
  emailForm: FormGroup;
  invitedPeople: string[] = [];

  constructor(
    private fb: FormBuilder,
    private eventService: EventService,
  ) {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  invitePerson() {
    if (!this.emailForm || !this.event) {
      return;
    }
    if (this.emailForm.valid) {
      const value = this.emailForm.value.email;
      this.invitedPeople.push(value);

      const req: EventInviteRequest = {
        email: value,
      };

      this.eventService.inviteGuests(this.event.id, req).subscribe(() => {});
      this.emailForm.reset();
    }
  }
  unInvite(personEmail: string) {
    this.invitedPeople = this.invitedPeople.filter(
      (person) => person !== personEmail,
    );
  }
}
