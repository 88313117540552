import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CampaignEventRankingsResponse } from '@models/campaigns/dto/event-campaign-rating.response';
import { Router, RouterModule } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { Event } from '@models/events/event.model';
import { CampaignRankingCardComponent } from '@modules/events/components/campaign-ranking-card/campaign-ranking-card.component';
import { NotificationService } from '@services/shared/notification.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgIf, NgStyle } from '@angular/common';
import { EventCampaignCategory } from '@models/campaigns/event-campaign-category.enum';

@Component({
  selector: 'app-campaign-ranking',
  standalone: true,
  imports: [
    RouterModule,
    NgStyle,
    TranslateModule,
    NgIf,
    CampaignRankingCardComponent,
  ],
  providers: [NotificationService],
  templateUrl: './campaign-ranking.component.html',
  styleUrl: './campaign-ranking.component.scss',
})
export class CampaignRankingComponent implements OnInit, OnChanges {
  @Input() campaignData?: CampaignEventRankingsResponse;
  @Input() isLoggedUserHost?: boolean | undefined | 0;
  isWinner: boolean = true;
  isVotingOver?: boolean = true;
  @Input() event?: Event;
  winners?: CampaignEventRankingsResponse;

  constructor(
    private router: Router,
    private clipboard: Clipboard,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.checkIfVotingEnded(new Date('2024-05-12T23:59:59'));
  }

  ngOnChanges(changes: SimpleChanges) {
    this.checkIfVotingEnded(new Date('2024-05-12T23:59:59'));
  }

  checkIfVotingEnded(date: Date): void {
    const today = new Date();
    this.isVotingOver = today.getTime() > date.getTime();

    if (this.event && this.event.eventCampaign && this.campaignData) {
      let title = '';
      this.isWinner = true;
      if (this.campaignData.ranking === 1) {
        title = 'APP.VOTING.RATING.WINNER';
      } else if (this.campaignData.ranking === 2) {
        title = 'APP.VOTING.RATING.SECOND_WINNER';
      } else if (this.campaignData.ranking === 3) {
        title = 'APP.VOTING.RATING.THIRD_WINNER';
      } else if (this.campaignData.ranking >= 4) {
        this.isWinner = false;
        title = 'APP.VOTING.RATING.WINNER_OF_HEARTS';
      }

      this.winners = this.campaignData;
      this.winners.title = title;
    }
  }
  share() {
    if (this.event) {
      window.navigator.share({
        text: this.event.description,
        title: this.event.name,
        url: this.event.getVotingLink(),
      });
    }
  }

  copyLink() {
    if (this.event) {
      this.clipboard.copy(this.event.getVotingLink());
      this.notificationService.success(
        this.translateService.instant(
          'APP.AFFILIATE_PRODUCTS_PAGE_LIST.SUCCESS.LINK_COPIED',
        ),
      );
    }
  }

  goVote() {
    if (this.event) {
      const currentUrl = this.event.getVotingLinkRelative();
      this.router.navigateByUrl(currentUrl);
    }
  }

  protected readonly EventCampaignCategory = EventCampaignCategory;
}
