import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { map } from 'rxjs/operators';
import { EventComment } from '@models/event-comments/event-comment.model';
import { AddEventCommentRequest } from '@models/event-comments/dto/add-event-comment.request';
import { EventCommentReaction } from '@models/event-comments/event-comment-reaction.model';

@Injectable()
export class EventCommentService {
  constructor(private api: ApiService) {}

  getComments(eventId: number, afterDate?: Date): Observable<EventComment[]> {
    const params = new Map<string, string>();
    if (afterDate) {
      params.set('afterDate', afterDate.toISOString());
    }

    return this.api
      .get(`${env.api}/events/${eventId}/comments`, params)
      .pipe(map(this.mapComments));
  }

  private mapComments(res: EventComment[]) {
    return res?.map((it) => new EventComment(it));
  }

  addComment(
    eventId: number,
    req: AddEventCommentRequest,
  ): Observable<EventComment> {
    return this.api
      .post(`${env.api}/events/${eventId}/comments`, req)
      .pipe(map((it) => new EventComment(it)));
  }

  removeComment(eventId: number, commentId: number): Observable<void> {
    return this.api.delete(
      `${env.api}/events/${eventId}/comments/${commentId}`,
    );
  }

  addCommentReaction(
    eventId: number,
    commentId: number,
    emoji: string,
  ): Observable<EventCommentReaction> {
    return this.api
      .post(`${env.api}/events/${eventId}/comments/${commentId}/reaction`, {
        emoji,
      })
      .pipe(map((it) => new EventCommentReaction(it)));
  }
}
