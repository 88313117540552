@if (event) {
  <div
    class="attendee-list-dialog event-dialog-content tabs-dialog"
    mat-dialog-content
  >
    @if (!displayMessagesDialog) {
      <div class="guest-list-dialog">
        <div (click)="close()" class="close-button">
          <i class="fal fa-times-circle"></i>
        </div>

        <div class="dialog-body">
          <div class="dialog-title">
            {{ "APP.EVENT_RSVP.GUEST_LIST" | translate }}
          </div>

          <div class="search-wrapper">
            <app-search-box
              class="search-box"
              [filteredProductNames]="filteredAttendeesNames"
              [placeholder]="'APP.EVENT_RSVP.SEARCH_GUESTS'"
              (valueChanged)="onSearchTermChange($event)"
            ></app-search-box>
            <div class="sort-btn" mat-button [matMenuTriggerFor]="menu">
              <i class="far fa-sort-alt icon"></i>
            </div>
            <mat-menu
              #menu="matMenu"
              xPosition="before"
              class="mat-menu-actions-container"
            >
              <button
                mat-menu-item
                (click)="onSortByName()"
                [class.selected]="sortType === 'name'"
              >
                {{ "APP.NAME" | translate }}
              </button>
              <button
                mat-menu-item
                (click)="onSortByDate(true)"
                [class.selected]="sortType === 'date-asc'"
              >
                {{ "APP.DATE_ASCENDING" | translate }}
              </button>
              <button
                mat-menu-item
                (click)="onSortByDate(false)"
                [class.selected]="sortType === 'date-desc'"
              >
                {{ "APP.DATE_DESCENDING" | translate }}
              </button>
              <button
                mat-menu-item
                (click)="onSortByStatus()"
                [class.selected]="sortType === 'status'"
              >
                {{ "APP.STATUS" | translate }}
              </button>
            </mat-menu>
          </div>

          <div class="horizontal-scroll-container">
            <mat-tab-group
              mat-stretch-tabs="false"
              class="attendee-list-tabs"
              [selectedIndex]="selectedTabIndex"
            >
              <mat-tab
                label="{{ 'APP.RSVP_OPTION.ALL' | translate }} ({{
                  getAttendeesLength(rsvp_all)
                }})"
              >
                <div
                  class="attendee-list"
                  [class.restricted-view]="restrictedView"
                >
                  @if (restrictedView) {
                    <app-restricted-access
                      (rsvpChange)="handleRsvpChange($event)"
                    ></app-restricted-access>
                  }
                  @for (attendee of getAttendees(rsvp_all); track attendee.id) {
                    <app-attendee-row
                      [attendee]="attendee"
                      [font]="font"
                      [rsvpTypes]="rsvpTypes"
                      [isLoggedUserHost]="
                        loggedUser &&
                        loggedUser.id &&
                        event.isUserHost(loggedUser.id)
                      "
                      [isAdmin]="loggedUser && loggedUser.isAdmin()"
                      [showGuestName]="event.showGuestList"
                      (selectedRSVPOption)="selectStatus(attendee, $event)"
                      (displayMsgForNotAttendingUser)="
                        displayMsgForNotAttendingUser(attendee)
                      "
                    >
                    </app-attendee-row>
                  }
                </div>
              </mat-tab>
              @for (rsvpOption of event.rsvpOptions; track rsvpOption.type) {
                <mat-tab
                  label="{{ rsvpOption.name | translate }} ({{
                    getAttendeesLength(rsvpOption.type)
                  }})"
                >
                  <div
                    class="attendee-list"
                    [class.restricted-view]="restrictedView"
                  >
                    @if (restrictedView) {
                      <app-restricted-access />
                    }
                    @for (
                      attendee of getAttendees(rsvpOption.type);
                      track attendee.id
                    ) {
                      <app-attendee-row
                        [attendee]="attendee"
                        [font]="font"
                        [rsvpTypes]="rsvpTypes"
                        [isLoggedUserHost]="
                          loggedUser &&
                          loggedUser.id &&
                          event.isUserHost(loggedUser.id)
                        "
                        [isAdmin]="loggedUser && loggedUser.isAdmin()"
                        [showGuestName]="event.showGuestList"
                        (selectedRSVPOption)="selectStatus(attendee, $event)"
                        (displayMsgForNotAttendingUser)="
                          displayMsgForNotAttendingUser(attendee)
                        "
                      >
                      </app-attendee-row>
                    }
                  </div>
                </mat-tab>
              }
              @if (
                event.requireAttendeeApproval &&
                (event.isUserHost(loggedUser?.id) ||
                  eventCohosts.includes(loggedUser))
              ) {
                <mat-tab
                  label="{{
                    'APP.RSVP_OPTION.PENDING_APPROVAL' | translate
                  }} ({{ getAttendeesLength(rsvp_pending_approval) }})"
                >
                  <div
                    class="attendee-list"
                    [class.restricted-view]="restrictedView"
                  >
                    @if (restrictedView) {
                      <app-restricted-access
                        (rsvpChange)="handleRsvpChange($event)"
                      ></app-restricted-access>
                    }
                    @for (
                      attendee of getAttendees(rsvp_pending_approval);
                      track attendee.id
                    ) {
                      <app-attendee-row
                        [attendee]="attendee"
                        [font]="font"
                        [rsvpTypes]="rsvpTypes"
                        [isLoggedUserHost]="
                          loggedUser &&
                          loggedUser.id &&
                          event.isUserHost(loggedUser.id)
                        "
                        [showGuestName]="event.showGuestList"
                        (pendingChange)="onPendingChange(attendee, $event)"
                        (displayMsgForNotAttendingUser)="
                          displayMsgForNotAttendingUser(attendee)
                        "
                      >
                      </app-attendee-row>
                    }
                  </div>
                </mat-tab>
              } @else if (
                event.waitlistEnabled &&
                (event.isUserHost(loggedUser?.id) ||
                  eventCohosts.includes(loggedUser))
              ) {
                <mat-tab
                  label="{{ 'APP.RSVP_OPTION.WAITLIST' | translate }} ({{
                    getAttendeesLengthByStatus(EventAttendeeStatus.ON_WAITLIST)
                  }})"
                >
                  <div
                    class="attendee-list"
                    [class.restricted-view]="restrictedView"
                  >
                    @if (restrictedView) {
                      <app-restricted-access />
                    }
                    @for (
                      attendee of getAttendeesByStatus(
                        EventAttendeeStatus.ON_WAITLIST
                      );
                      track attendee.id
                    ) {
                      <app-attendee-row
                        [attendee]="attendee"
                        [font]="font"
                        [rsvpTypes]="rsvpTypes"
                        [isLoggedUserHost]="
                          loggedUser &&
                          loggedUser.id &&
                          event.isUserHost(loggedUser.id)
                        "
                        [showGuestName]="event.showGuestList"
                        [onWaitList]="true"
                        (displayMsgForNotAttendingUser)="
                          displayMsgForNotAttendingUser(attendee)
                        "
                      >
                      </app-attendee-row>
                    }
                  </div>
                </mat-tab>
              }
            </mat-tab-group>
          </div>
        </div>
      </div>
    } @else if (selectedAttendee) {
      <div class="not-attending-msg-dialog">
        <app-back-button
          class="go-back-button"
          [size]="35"
          (click)="displayGuestListDialog()"
        ></app-back-button>
        <div class="dialog-body">
          <div class="dialog-title">
            {{
              "APP.EVENT_RSVP.MSG_FROM"
                | translate: { guestName: selectedAttendee.getName() }
            }}
          </div>
          <div class="attendee-message">{{ selectedAttendee.message }}</div>
        </div>
      </div>
    }
  </div>
}
