import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { Helpers } from '@utils/helpers';

@Component({
  selector: 'app-success',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './success.dialog.html',
  styleUrl: './success.dialog.scss',
})
export class SuccessDialog {
  title = '';
  message = '';
  okLabel = 'OK';
  font?: EventTemplateFont;

  constructor(
    public dialogRef: MatDialogRef<SuccessDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data) {
      this.title = data.title;
      this.message = data.message;
      this.font = data.font;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
