import { Inject, Injectable, Optional } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

import { Router } from '@angular/router';
import { HOST_URL } from 'app/tokens/host-url';

export interface PageMetadata {
  title: string;
  description: string;
  keywords: string[];
  type: string;
  image: string;
}

const defaultMetadata: PageMetadata = {
  title: 'Du bist zur Party eingeladen! Feiere mit!',
  description:
    'Mach dich bereit, mit uns zu feiern! Du bist persönlich eingeladen, bei unserem Event mitzufeiern. Lass es dir nicht entgehen - sieh, was auf dich wartet!',
  keywords: ['event', 'party'],
  type: 'website',
  image: 'https://app.eventpage.ai/assets/share.png',
};

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  constructor(
    private metaTagService: Meta,
    private titleService: Title,
    @Optional() @Inject(HOST_URL) private hostUrl: string,
    private router: Router,
  ) {}

  public updateMetadata(
    metadata: Partial<PageMetadata>,
    index: boolean = true,
  ): void {
    const pageMetadata: PageMetadata = { ...defaultMetadata, ...metadata };
    const metatags: MetaDefinition[] =
      this.generateMetaDefinitions(pageMetadata);

    if (!this.hostUrl && window && window.location) {
      this.hostUrl = window.location.protocol + '//' + window.location.hostname;
    }

    metatags.push({
      property: 'og:url',
      content: `${this.hostUrl}${this.router.url}`,
    });

    const siteName =
      this.hostUrl && this.hostUrl.includes('partypage.ai')
        ? 'partypage.ai'
        : 'eventpage.ai';

    metatags.push({
      property: 'og:site_name',
      content: siteName,
    });

    this.metaTagService.addTags(
      [{ name: 'robots', content: index ? 'index, follow' : 'noindex' }],
      false,
    );

    metatags.forEach((metatag) => {
      this.metaTagService.updateTag(
        metatag,
        metatag.name && metatag.name !== ''
          ? `name='${metatag.name}'`
          : `property='${metatag.property}'`,
      );
    });

    this.titleService.setTitle(pageMetadata.title);
  }

  private generateMetaDefinitions(metadata: PageMetadata): MetaDefinition[] {
    return [
      { name: 'title', content: metadata.title },
      { property: 'og:title', content: metadata.title },

      { name: 'description', content: metadata.description },
      { property: 'og:description', content: metadata.description },

      { property: 'og:image', content: metadata.image },

      { name: 'keywords', content: metadata.keywords.join(', ') },

      { property: 'og:type', content: metadata.type },
    ];
  }
}
