import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { EventAttendeeQuestionType } from '@models/event-attendance/event-attendee-question-type.enum';
import { EventAttendeeQuestion } from '@models/event-attendance/event-attendee-question.model';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModules } from '@utils/shared-modules';
import { TextEditableDirective } from '../../../../directives/text-editable.directive';

@Component({
  selector: 'app-event-rsvp-question',
  standalone: true,
  imports: [CommonModule, FormsModules, TranslateModule, TextEditableDirective],
  templateUrl: './event-rsvp-question.component.html',
  styleUrl: './event-rsvp-question.component.scss',
})
export class EventRsvpQuestionComponent implements OnInit {
  @Input() form?: FormGroup;
  @Input() question?: EventAttendeeQuestion;

  @Input() ngModelVal: any;

  @Input() matFormFieldClass = 'flex-input';
  @Input() matFormFieldAppearance: MatFormFieldAppearance = 'outline';

  @Output() ngModelValChange = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.form && this.question) {
      this.addQuestionControl();
    }
  }

  addQuestionControl() {
    if (this.question && this.form) {
      const controlName = `question#${this.question.id}`;
      const control = this.fb.control(
        this.ngModelVal || '',
        this.question.isRequired ? Validators.required : [],
      );
      this.form.addControl(controlName, control);
    }
  }

  onNgModelChange(val: any): void {
    this.ngModelValChange.emit(val);
  }

  protected readonly EventAttendeeQuestionType = EventAttendeeQuestionType;
}
