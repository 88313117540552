@if (event) {
  <div
    class="dialog-body"
    [class.dialog-body-two-buttons]="layout === RSVPLayout.TWO_BUTTONS"
  >
    <div class="dialog-title mobile-only">
      @if (isAttendee) {
        {{ "APP.EVENT_RSVP.EDIT_TITLE" | translate }}
      } @else {
        {{ "APP.EVENT_RSVP.TITLE" | translate }}
      }
    </div>
    <div class="rsvp-title-wrapper">
      <h1>
        {{
          eventPlusOnesInfo.title ||
            ("APP.EVENT_RSVP.PLUS_ONES_DATA.TITLE" | translate)
        }}
      </h1>
      <span>
        {{
          eventPlusOnesInfo.description ||
            ("APP.EVENT_RSVP.PLUS_ONES_DATA.DESCRIPTION" | translate)
        }}
      </span>
    </div>

    @if (event.plusOnesLimit && form) {
      <form [formGroup]="form">
        <div formArrayName="plusOnes">
          @for (plusOneForm of plusOnes.controls; track plusOneForm) {
            <div class="plus-one-info">
              <div>
                {{
                  eventPlusOnesInfo.plusOneItemLabel ||
                    ("APP.EVENT_RSVP.PLUS_ONES_DATA.PLUS_ONE_ITEM_LABEL"
                      | translate)
                }}
                {{ $index + 1 }}
              </div>
              <div class="remove-plus-one" (click)="removePlusOne($index)">
                {{ "APP.REMOVE" | translate }}
              </div>
            </div>
            <div
              [formGroupName]="$index"
              class="flex-inputs-cont plus-one-cont"
            >
              @if (
                event.eventTemplate?.rsvpSettings?.hasPlusOnesAdditionalData()
              ) {
                @if (event.eventTemplate?.rsvpSettings?.showPlusOnesFirstName) {
                  <mat-form-field
                    [appearance]="formAppearance"
                    class="flex-input"
                  >
                    <mat-label>{{ "APP.FIRST_NAME" | translate }}</mat-label>
                    <input
                      type="text"
                      matInput
                      formControlName="firstName"
                      placeholder=""
                    />
                  </mat-form-field>
                }
                @if (event.eventTemplate?.rsvpSettings?.showPlusOnesLastName) {
                  <mat-form-field
                    [appearance]="formAppearance"
                    class="flex-input"
                  >
                    <mat-label>{{ "APP.LAST_NAME" | translate }}</mat-label>
                    <input
                      type="text"
                      matInput
                      formControlName="lastName"
                      placeholder=""
                    />
                  </mat-form-field>
                }
                @if (event.eventTemplate?.rsvpSettings?.showPlusOnesEmail) {
                  <mat-form-field
                    [appearance]="formAppearance"
                    class="flex-input email-input"
                  >
                    <mat-label>{{ "APP.EMAIL" | translate }}</mat-label>
                    <input type="text" matInput formControlName="email" />
                  </mat-form-field>
                }
                @if (event.eventTemplate?.rsvpSettings?.showPlusOnesPhone) {
                  <mat-form-field
                    class="flex-input phone-form"
                    [appearance]="formAppearance"
                  >
                    <app-tel-input formControlName="phone"></app-tel-input>
                    @if (
                      getFormGroup(plusOneForm).controls["phone"].hasError(
                        "custom"
                      )
                    ) {
                      <mat-error>
                        {{
                          getFormGroup(plusOneForm).controls["phone"].getError(
                            "custom"
                          )
                        }}
                      </mat-error>
                    } @else if (
                      getFormGroup(plusOneForm).controls["phone"].hasError(
                        "required"
                      )
                    ) {
                      <mat-error>
                        {{ "APP.AUTH.ERRORS.PHONE_REQUIRED" | translate }}
                      </mat-error>
                    } @else if (
                      getFormGroup(plusOneForm).controls["phone"].hasError(
                        "invalidNumber"
                      )
                    ) {
                      <mat-error>
                        {{ "APP.AUTH.ERRORS.PHONE_INVALID" | translate }}
                      </mat-error>
                    }
                  </mat-form-field>
                }

                @if (event.eventTemplate?.attendeeQuestions) {
                  @for (
                    question of event.eventTemplate?.attendeeQuestions;
                    track question.id
                  ) {
                    <app-event-rsvp-question
                      [form]="getFormGroup(plusOneForm)"
                      [question]="question"
                      matFormFieldClass="w-100"
                      [matFormFieldAppearance]="formAppearance"
                    ></app-event-rsvp-question>
                  }
                }
              }
            </div>
          }
        </div>

        <div class="text-btn add-plus-one-btn" (click)="addPlusOne()">
          {{
            eventPlusOnesInfo.addLabel ||
              ("APP.EVENT_RSVP.PLUS_ONES_DATA.ADD_LABEL" | translate)
          }}
        </div>
      </form>
    }
  </div>
  <div mat-dialog-actions class="dialog-actions">
    @if (layout === RSVPLayout.TWO_BUTTONS && visual) {
      <button
        class="btn btn-invitation-transparent"
        [style.background]="
          visual.backgroundColor ? visual.backgroundColor : ''
        "
        (click)="onNext()"
        [class.spinner]="inProgress"
        [disabled]="inProgress"
      >
        {{ "APP.NEXT" | translate }}
      </button>
    } @else {
      <button
        class="btn btn-primary"
        (click)="onNext()"
        [class.spinner]="inProgress"
        [disabled]="inProgress"
      >
        {{ "APP.NEXT" | translate }}
      </button>
    }
  </div>
}
