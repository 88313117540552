export enum EventAffiliateProductType {
  AFTER_CREATION = 'AFTER_CREATION',
  AFTER_ATTEND_GIFT = 'AFTER_ATTEND_GIFT',
  AFTER_ATTEND = 'AFTER_ATTEND',
  AFTER_ATTEND_SHOPPING_LIST = 'AFTER_ATTEND_SHOPPING_LIST',
}

export namespace EventAffiliateProductType {
  export function getAllEventAffiliateProductTypes(): EventAffiliateProductType[] {
    return [
      EventAffiliateProductType.AFTER_CREATION,
      EventAffiliateProductType.AFTER_ATTEND_GIFT,
      EventAffiliateProductType.AFTER_ATTEND,
      EventAffiliateProductType.AFTER_ATTEND_SHOPPING_LIST,
    ];
  }

  export function getDisplayName(type?: EventAffiliateProductType): string {
    switch (type) {
      case EventAffiliateProductType.AFTER_CREATION:
        return '';
      case EventAffiliateProductType.AFTER_ATTEND_GIFT:
        return '';
      case EventAffiliateProductType.AFTER_ATTEND:
        return '';
      case EventAffiliateProductType.AFTER_ATTEND_SHOPPING_LIST:
        return '';
      default:
        return '';
    }
  }
}
