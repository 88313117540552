import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { EventHost } from '@models/events/event-host.model';
import { Event } from '@models/events/event.model';
import { SuccessDialog } from '@modules/customer/dialogs/success/success.dialog';
import { YesNoDialog } from '@modules/customer/dialogs/yes-no/yes-no.dialog';
import { AddHostDialog } from '@modules/events/dialogs/add-host/add-host.dialog';
import { UserAvatarComponent } from '@modules/shared/components/user-avatar/user-avatar.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';

@Component({
  selector: 'app-event-hosts',
  standalone: true,
  imports: [CommonModule, TranslateModule, UserAvatarComponent],
  providers: [EventService, NotificationService],
  templateUrl: './event-hosts.component.html',
  styleUrl: './event-hosts.component.scss',
})
export class EventHostsComponent implements OnInit {
  @Input() event?: Event;
  @Input() editMode = false;
  @Input() isVotingView? = false;
  multipleHosts: boolean = false;

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
    private eventService: EventService,
    private notificationService: NotificationService,
  ) {}

  addHost() {
    if (!this.event) {
      return;
    }

    const dialogRef = this.dialog.open(AddHostDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        event: this.event,
      },
      panelClass: ['normal-dialog'],
    });
    dialogRef.afterClosed().subscribe((host?: EventHost) => {
      if (host) {
        this.event?.hosts?.push(host);

        this.dialog.open(SuccessDialog, {
          maxWidth: '602px',
          maxHeight: '100vh',
          width: '100%',
          height: 'auto',
          data: {
            title: this.translateService.instant('APP.EVENT_EDIT.ADD_HOST'),
            message:
              this.translateService.instant(
                'APP.EVENT_EDIT.ADD_HOST_SUCCESS_TEXT',
              ) +
              ' ' +
              host.user?.email,
            font: this.event?.eventTemplate?.font,
          },
          panelClass: ['normal-dialog'],
        });
      }
    });
  }

  removeHost(host: EventHost) {
    const dialogRef = this.dialog.open(YesNoDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        title: this.translateService.instant('APP.EVENT_EDIT.REMOVE_HOST'),
        message: this.translateService.instant(
          'APP.EVENT_EDIT.REMOVE_HOST_TEXT',
        ),
        user: host.user,
        font: this.event?.eventTemplate?.font,
        isDeleteDialog: true,
      },
      panelClass: ['normal-dialog'],
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res && this.event) {
        this.eventService.removeHost(this.event?.id, host.id).subscribe({
          next: () => {
            if (this.event?.hosts) {
              this.event.hosts = this.event.hosts.filter(
                (eventHost) => eventHost.id !== host.id,
              );
            }
          },
          error: () => {
            this.notificationService.error(
              this.translateService.instant(
                'APP.EVENT_EDIT.ERRORS.REMOVE_HOST',
              ),
            );
          },
        });
      }
    });
  }

  get font(): EventTemplateFont | undefined {
    return this.event?.eventTemplate?.font;
  }

  ngOnInit(): void {
    const activeCoHosts = this.event?.getActiveCoHosts?.();
    if (activeCoHosts && activeCoHosts.length > 0) {
      this.multipleHosts = true;
    }
  }
}
