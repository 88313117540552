<div class="add-to-calendar-dialog event-dialog-content" mat-dialog-content>
  <div (click)="close()" class="close-button">
    <i class="fal fa-times-circle"></i>
  </div>
  <div class="dialog-body">
    <div class="dialog-title">
      {{ "APP.ADD_TO_CALENDAR.TITLE" | translate }}
    </div>
    <div class="calendar-actions">
      <div class="btn btn-add-calendar" (click)="addToGoogleCalendar()">
        {{ "APP.ADD_TO_CALENDAR.GOOGLE" | translate }}
        <i class="fab fa-google"></i>
      </div>
      <div class="btn btn-add-calendar" (click)="addToOutlookCalendar()">
        {{ "APP.ADD_TO_CALENDAR.OUTLOOK" | translate }}
      </div>
      <div class="btn btn-add-calendar" (click)="addToAppleCalendar()">
        {{ "APP.ADD_TO_CALENDAR.APPLE" | translate }}
        <i class="fab fa-apple"></i>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <div class="btn btn-primary" (click)="close()">
      {{ "APP.CLOSE" | translate }}
    </div>
  </div>
</div>
