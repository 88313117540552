import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { EventComment } from '@models/event-comments/event-comment.model';
import { Event } from '@models/events/event.model';
import { User } from '@models/users/user.model';
import { UserAvatarComponent } from '@modules/shared/components/user-avatar/user-avatar.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

@Component({
  selector: 'app-event-comment',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatMenuModule,
    NgxTippyModule,
    PickerComponent,
    UserAvatarComponent,
  ],
  templateUrl: './event-comment.component.html',
  styleUrl: './event-comment.component.scss',
})
export class EventCommentComponent {
  @Input() loggedUser?: User;
  @Input() event?: Event;
  @Input() comment?: EventComment;

  @Output() addCommentReaction = new EventEmitter<{
    emoji: string;
    commentId: number;
  }>();

  @Output() removeComment = new EventEmitter<number>();

  addCommentReactionFromPicker(event: any, commentId: number) {
    this.onAddCommentReaction(event.emoji.native, commentId);
  }

  onAddCommentReaction(emoji: string, commentId: number) {
    if (!this.isUserHostOrAttendee) {
      return;
    }

    this.addCommentReaction.emit({ emoji, commentId });
  }

  onRemoveComment(commentId: number) {
    this.removeComment.emit(commentId);
  }

  get isUserHostOrAttendee(): boolean {
    return (
      !!this.event &&
      (this.event.isUserHost(this.loggedUser?.id) ||
        this.event.isNonPendingActiveAttendee(this.loggedUser?.id))
    );
  }
}
