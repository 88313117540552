import { ClassData } from '@models/class-data';

export class GoogleFont {
  family: string;
  category: string;
  variants: string[];

  constructor(json: ClassData<GoogleFont>) {
    this.family = json.family;
    this.category = json.category;
    this.variants = json.variants;
  }
}
