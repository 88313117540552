<div class="success-dialog" mat-dialog-content>
  <div (click)="close()" class="close-button">
    <i class="fal fa-times-circle"></i>
  </div>
  <div class="dialog-body">
    <div class="dialog-title">{{ title }}</div>
    <div>
      <div class="message">{{ message }}</div>
    </div>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <div class="btn btn-primary" (click)="close()">{{ okLabel }}</div>
  </div>
</div>
