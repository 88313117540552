import { ClassData } from '@models/class-data';
import { EventAsset } from '@models/events/event-asset.model';
import { User } from '@models/users/user.model';
import { CommentStatus } from './comment-status.enum';
import moment from 'moment';
import {
  EventCommentReaction,
  EventCommentReactionUsersInfo,
} from './event-comment-reaction.model';

export class EventComment {
  id: number;
  createdAt?: Date;

  eventId: number;
  userId: number;
  user?: User;

  parentCommentId?: number;

  status: CommentStatus;
  comment: string;
  assets?: EventAsset[];
  reactions?: EventCommentReaction[];

  constructor(json: ClassData<EventComment>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.eventId = +json.eventId;
    this.userId = +json.userId;

    if (json.user) {
      this.user = new User(json.user);
    }

    if (json.parentCommentId !== undefined) {
      this.parentCommentId = +json.parentCommentId;
    }

    this.status = json.status;
    this.comment = json.comment;
    if (json.assets) {
      this.assets = this.mapEventAssets(json.assets);
    }
    if (json.reactions) {
      this.reactions = this.mapEventReactions(json.reactions);
    }
  }

  getCommentDisplayTime(): string {
    if (!this.createdAt) {
      return '';
    }

    const createdAtMoment = moment(this.createdAt);

    if (createdAtMoment.isSame(new Date(), 'day')) {
      return createdAtMoment.format('HH:mm');
    } else if (moment().diff(createdAtMoment, 'days') <= 7) {
      return createdAtMoment.format('ddd HH:mm');
    } else {
      return createdAtMoment.format('MMM D HH:mm');
    }
  }

  getReactionsForDisplay(): { emoji: string; num: number }[] {
    const reactionsMap = new Map<string, number>();
    this.reactions?.forEach((reaction) => {
      if (!reactionsMap.has(reaction.emoji)) {
        reactionsMap.set(reaction.emoji, 1);
      } else {
        reactionsMap.set(reaction.emoji, reactionsMap.get(reaction.emoji)! + 1);
      }
    });

    return Array.from(reactionsMap.entries()).map(([emoji, num]) => ({
      emoji,
      num,
    }));
  }

  getEventCommentReactionUsersInfo(): EventCommentReactionUsersInfo[] {
    const eventCommentReactionUsersInfos: EventCommentReactionUsersInfo[] = [];

    this.reactions?.forEach((reaction) => {
      const reactionIndex = eventCommentReactionUsersInfos.findIndex(
        (commentReaction) => commentReaction.emoji === reaction.emoji,
      );

      if (reactionIndex === -1) {
        eventCommentReactionUsersInfos.push(
          new EventCommentReactionUsersInfo({
            id: reaction.id,
            createdAt: reaction.createdAt,

            eventCommentId: reaction.eventCommentId,

            emoji: reaction.emoji,

            users: reaction.user ? [reaction.user] : [],
          }),
        );
      } else {
        if (
          eventCommentReactionUsersInfos[reactionIndex].users &&
          reaction.user
        ) {
          eventCommentReactionUsersInfos[reactionIndex].users?.push(
            reaction.user,
          );
        }
      }
    });

    return eventCommentReactionUsersInfos.sort(
      (a, b) => b.getNumUsers() - a.getNumUsers(),
    );
  }

  private mapEventAssets(assets: EventAsset[]): EventAsset[] {
    return assets.map((it) => new EventAsset(it));
  }

  private mapEventReactions(
    assets: EventCommentReaction[],
  ): EventCommentReaction[] {
    return assets.map((it) => new EventCommentReaction(it));
  }
}
