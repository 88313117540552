import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { Event } from '@models/events/event.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventCommentService } from '@services/events/event-comment.service';
import { EventStore } from '@services/stores/event.store';
import { FormsModules } from '@utils/shared-modules';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, Subscription, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { EventComment } from '@models/event-comments/event-comment.model';
import { UserAvatarComponent } from '@modules/shared/components/user-avatar/user-avatar.component';
import { TextEditableDirective } from 'app/directives/text-editable.directive';
import { User } from '@models/users/user.model';
import { ImagePickerDialog } from '@modules/shared/dialogs/image-picker/image-picker.dialog';
import {
  MatDialog,
  MatDialogRef,
  MatDialogState,
} from '@angular/material/dialog';
import {
  Asset,
  createDataUrl,
  getAssetFromURL,
} from '@models/shared/asset.model';
import { AddEventCommentRequest } from '@models/event-comments/dto/add-event-comment.request';
import { EventCommentComponent } from '../event-comment/event-comment.component';
import { NotificationService } from '@services/shared/notification.service';

@Component({
  selector: 'app-event-comments',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    UserAvatarComponent,
    TextEditableDirective,
    EventCommentComponent,
  ],
  providers: [EventCommentService, NotificationService],
  templateUrl: './event-comments.component.html',
  styleUrl: './event-comments.component.scss',
})
export class EventCommentsComponent implements OnDestroy {
  event?: Event;
  comments: EventComment[] = [];

  pollingStarted = false;
  lastGetCommentsRequestDate?: Date;

  newComment = '';

  commentImageUrl?: string;
  commentImageAsset?: Asset;

  imagePickerDialogRef?: MatDialogRef<ImagePickerDialog>;

  commentsPollingTimer?: Subscription;

  @Input() loggedUser?: User;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(
    private eventCommentService: EventCommentService,
    private eventStore: EventStore,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event && event.id > 0) {
        this.event = event;

        if (!this.pollingStarted) {
          // this.pollingStarted = true;
          if (this.commentsPollingTimer && !this.commentsPollingTimer.closed) {
            this.commentsPollingTimer.unsubscribe();
          }

          this.commentsPollingTimer = timer(0, 15000)
            .pipe(
              switchMap(() =>
                this.eventCommentService.getComments(
                  event.id,
                  this.lastGetCommentsRequestDate,
                ),
              ),
              takeUntil(this._onDestroy),
            )
            .subscribe((comments: any) => {
              this.addComments(comments);
              this.lastGetCommentsRequestDate = new Date();
            });
        }
      }
    });
  }

  addComments(comments: EventComment[]) {
    for (const comment of comments) {
      if (!this.commentExists(comment)) {
        this.comments.unshift(comment);
      } else {
        const commentIndex = this.comments.findIndex(
          (it) => it.id === comment.id,
        );
        this.comments[commentIndex] = comment;
      }
    }
  }

  addComment() {
    if (
      !this.event ||
      (this.newComment === '' &&
        !this.commentImageUrl &&
        !this.commentImageAsset)
    ) {
      return;
    }

    const req: AddEventCommentRequest = {
      comment: this.newComment,
    };

    if (this.commentImageUrl && this.commentImageUrl !== '') {
      req.commentAssets = [getAssetFromURL(this.commentImageUrl)];
    } else if (this.commentImageAsset && this.commentImageAsset.base64 !== '') {
      req.commentAssets = [this.commentImageAsset];
    }

    this.eventCommentService.addComment(this.event.id, req).subscribe({
      next: () => {
        this.updateComments();
        this.newComment = '';
        this.commentImageAsset = undefined;
        this.commentImageUrl = undefined;
      },
      error: (err) => {
        this.notificationService.error(
          this.translateService.instant('APP.COMMENTS.ERRORS.ADD'),
        );
      },
    });
  }

  removeComment(commentId: number) {
    if (!this.event || !commentId) {
      return;
    }

    this.eventCommentService
      .removeComment(this.event.id, commentId)
      .subscribe(() => {
        this.comments = this.comments.filter(
          (comment) => comment.id !== commentId,
        );
      });
  }

  updateComments() {
    if (!this.event) {
      return;
    }

    this.eventCommentService
      .getComments(this.event.id, this.lastGetCommentsRequestDate)
      .subscribe((comments) => {
        this.addComments(comments);
        this.lastGetCommentsRequestDate = new Date();
      });
  }

  openImagePicker() {
    if (
      this.imagePickerDialogRef &&
      this.imagePickerDialogRef.getState() !== MatDialogState.CLOSED
    ) {
      return;
    }

    this.imagePickerDialogRef = this.dialog.open(ImagePickerDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        event: this.event,
        gifsPreselected: true,
      },
      panelClass: ['normal-dialog'],
    });

    this.imagePickerDialogRef.afterClosed().subscribe((res) => {
      if (!res) return;

      if (res.imageUrl && res.imageUrl !== '') {
        this.commentImageUrl = res.imageUrl;
        this.commentImageAsset = undefined;
      } else if (res.imageAsset) {
        this.commentImageAsset = res.imageAsset;
        this.commentImageUrl = undefined;
      }
    });
  }

  addCommentReaction(emoji: string, commentId: number) {
    if (this.event) {
      this.eventCommentService
        .addCommentReaction(this.event?.id, commentId, emoji)
        .subscribe((reaction) => {
          if (reaction.id) {
            this.updateComments();
          } else {
            const commentIndex = this.comments.findIndex(
              (it) => it.id === commentId,
            );
            this.comments[commentIndex].reactions = this.comments[
              commentIndex
            ].reactions?.filter(
              (react) => react.userId !== this.loggedUser?.id,
            );
          }
        });
    }
  }

  private commentExists(comment: EventComment): boolean {
    return this.comments.findIndex((it) => it.id === comment.id) !== -1;
  }

  get isUserHostOrNonPendingAttendee(): boolean {
    return (
      !!this.event &&
      (this.event.isUserHost(this.loggedUser?.id) ||
        this.event.isNonPendingActiveAttendee(this.loggedUser?.id))
    );
  }

  get isUserHostOrAttendee(): boolean {
    return (
      !!this.event && !!this.loggedUser &&
      (this.event.isUserHost(this.loggedUser.id) ||
        this.event.isAttendee(this.loggedUser.id) ||
        this.loggedUser.isAdmin())
    );
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  createDataUrl = createDataUrl;
}
