@if (event) {
  <div class="invite-friends-section">
    <div class="invite-friends-wrapper">
      <div class="title-section">
        <h1>{{ "APP.EVENT_AFTER_ATTEND.SPREAD_THE_WORD" | translate }}</h1>
        <p>
          {{ "APP.EVENT_AFTER_ATTEND.INVITE_ALL_YOUR_FRIENDS" | translate }}
        </p>
      </div>
      <div class="share-options-wrapper">
        <app-invite-share-options
          [event]="event"
          [showVoting]="false"
          [showQRCodeDownload]="false"
        />
        <app-invite-email [event]="event" />
      </div>
    </div>
  </div>
}
