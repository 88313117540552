import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Event } from '@models/events/event.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { environment as env } from '@environments/environment';
import { EventType } from '@models/events/event-type.enum';

@Component({
  selector: 'app-add-to-calendar',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './add-to-calendar.dialog.html',
  styleUrl: './add-to-calendar.dialog.scss',
})
export class AddToCalendarDialog {
  event?: Event;

  constructor(
    public dialogRef: MatDialogRef<AddToCalendarDialog>,
    @Inject(MAT_DIALOG_DATA) public data: Event,
  ) {
    this.event = this.data;
  }

  close() {
    this.dialogRef.close();
  }

  addToGoogleCalendar() {
    if (this.event) {
      const name =
        this.event.type === EventType.SPORTPAGE && this.isMizunoEvent
          ? `Mizuno Enerzy in the Dark Tour - ${this.event.name}`
          : this.event.name;
      const description = this.event.description;
      const startDate = moment(this.event.startDate)
        .utc()
        .format('YYYYMMDDTHHmmss[Z]');
      const endDate = moment(this.event.getEndDateForCalendar())
        .utc()
        .format('YYYYMMDDTHHmmss[Z]');
      const eventLink = this.event.getLink();
      const eventLocation =
        this.event.type === EventType.SPORTPAGE
          ? this.event.getSportPageLocationDisplay()
          : this.event.getAddressDisplayName();

      window.open(
        `https://calendar.google.com/calendar/u/0/r/eventedit?trp=true&text=${name}&dates=${startDate}/${endDate}&sprop=website:${eventLink}&sprop=name:${env.platform}&location=${eventLocation}&details=${description}%0AFull+details+at:+${eventLink}`,
        '_blank',
      );
    }
  }

  addToOutlookCalendar() {
    this.addToAppleCalendar();
    // if (this.event) {
    //   const name = this.event.name;
    //   const description = this.event.description;
    //   const startDate = this.event.startDate
    //     ? this.event.startDate.toISOString()
    //     : null;
    //   const endDate = this.event.getDisplayEndDateForCalendar();
    //   const eventLink = encodeURIComponent(this.event.getLink());
    //   const eventLocation = this.event.getAddressDisplayName();

    //   window.open(
    //     `https://outlook.office.com/calendar/0/deeplink/compose?rru=addevent&path=/calendar/action/compose&startdt=${startDate}&enddt=${endDate}&subject=${name}&location=${eventLocation}&body=${description}%0AFull%20details%20at%3A%20${eventLink}`,
    //     '_blank',
    //   );
    // }
  }

  addToAppleCalendar() {
    const location =
      this.event?.type === EventType.SPORTPAGE
        ? this.event?.getSportPageLocationDisplay()
        : this.event?.getAddressDisplayName();
    const name =
      this.event?.type === EventType.SPORTPAGE && this.isMizunoEvent
        ? `Mizuno Enerzy in the Dark Tour - ${this.event?.name}`
        : this.event?.name;
    if (this.event) {
      const calendarData = [
        'BEGIN:VCALENDAR',
        'PRODID:-//eventpage.ai GmbH//eventpage.ai//EN',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        'UID:' + this.event.uri,
        'SUMMARY:' + name,
        'DESCRIPTION:' + name,
        'DTSTART:' +
          (this.event.startDate
            ? moment(this.event.startDate).utc().format('YYYYMMDDTHHmmss') + 'Z'
            : null),
        'DTEND:' + this.event.getDisplayEndDateForCalendarIcs(),
        'URL:' + this.event.getLink(),
        'LOCATION:' + location,
        'DTSTAMP:' +
          moment(this.event.createdAt).utc().format('YYYYMMDDTHHmmss') +
          'Z',
        'END:VEVENT',
        'END:VCALENDAR',
      ].join('\r\n');

      window.open(encodeURI('data:text/calendar;charset=utf8,' + calendarData));
    }
  }

  get isMizunoEvent(): boolean {
    return !!this.event?.uri.includes('mizuno');
  }
}
