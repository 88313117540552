export enum EventAttendeeQuestionType {
  TEXT = 'TEXT',
  DROPDOWN = 'DROPDOWN',
  CHECKBOX = 'CHECKBOX',
  NUMBER = 'NUMBER',
}

export namespace EventAttendeeQuestionType {
  export function getAllEventAttendeeQuestionTypes(): EventAttendeeQuestionType[] {
    return [
      EventAttendeeQuestionType.TEXT,
      EventAttendeeQuestionType.DROPDOWN,
      EventAttendeeQuestionType.CHECKBOX,
      EventAttendeeQuestionType.NUMBER,
    ];
  }

  export function getDataFieldsDisplayName(
    type?: EventAttendeeQuestionType,
  ): string {
    switch (type) {
      case EventAttendeeQuestionType.TEXT:
        return 'APP.QUESTION_TYPE.SHORT';
      case EventAttendeeQuestionType.DROPDOWN:
        return 'APP.QUESTION_TYPE.MULTIPLE_CHOICE';
      case EventAttendeeQuestionType.CHECKBOX:
        return 'APP.QUESTION_TYPE.YES_OR_NO';
      case EventAttendeeQuestionType.NUMBER:
        return 'APP.QUESTION_TYPE.NUMBER';
      default:
        return '';
    }
  }
}
