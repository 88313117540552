import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AffiliateProduct } from '@models/affiliate-products/affiliate-product.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-affiliate-product-card',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './affiliate-product-card.component.html',
  styleUrl: './affiliate-product-card.component.scss',
})
export class AffiliateProductCardComponent {
  @Input() affiliateProduct?: AffiliateProduct;
  @Input() editView = false;

  @Output() remove = new EventEmitter<void>();

  removeProduct() {
    this.remove.emit();
  }

  onBuy() {
    if (this.affiliateProduct) {
      window.open(this.affiliateProduct.link, '_blank');
    }
  }
}
