export enum RSVPConfirmationPageSectionIconsColorType {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
  COLORED = 'COLORED',
  THEME = 'THEME',
}

export namespace RSVPConfirmationPageSectionIconsColorType {
  export function getAllRSVPConfirmationPagesSectionIconsColorTypes(): RSVPConfirmationPageSectionIconsColorType[] {
    return [
      RSVPConfirmationPageSectionIconsColorType.LIGHT,
      RSVPConfirmationPageSectionIconsColorType.DARK,
      RSVPConfirmationPageSectionIconsColorType.COLORED,
      RSVPConfirmationPageSectionIconsColorType.THEME,
    ];
  }

  export function getDisplayName(
    type?: RSVPConfirmationPageSectionIconsColorType,
  ): string {
    switch (type) {
      case RSVPConfirmationPageSectionIconsColorType.LIGHT:
        return 'APP.EVENT_AFTER_ATTEND.SECTIONS.THEME.LIGHT';
      case RSVPConfirmationPageSectionIconsColorType.DARK:
        return 'APP.EVENT_AFTER_ATTEND.SECTIONS.THEME.DARK';
      case RSVPConfirmationPageSectionIconsColorType.COLORED:
        return 'APP.EVENT_AFTER_ATTEND.SECTIONS.THEME.COLOURED';
      case RSVPConfirmationPageSectionIconsColorType.THEME:
        return 'APP.EVENT_AFTER_ATTEND.SECTIONS.THEME.THEME';
      default:
        return '';
    }
  }
}
