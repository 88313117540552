<div class="campaign-data">
  <div class="campaign-data">
    <span>{{ "APP.VOTING.RATING.VOTES" | translate }}</span>
    <span class="data-value"> {{ votes }}</span>
  </div>
  <div class="campaign-data">
    <span>{{ "APP.VOTING.RATING.RANK" | translate }}</span>
    <span class="data-value"> {{ ranking || '/' }}</span>
  </div>
</div>

