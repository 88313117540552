import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { EventRSVPConfirmationPageSection } from '@models/events/event-rsvp-confirmation-page-section.model';
import { EventSocialMediaLink } from '@models/events/event-social-media-link.model';
import { RSVPConfirmationPageSectionIconsColorType } from '@models/events/rsvp-confirmation-page-section-icons-color-type.enum';
import { SocialMediaType } from '@models/events/social-media-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/shared/notification.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Helpers } from '@utils/helpers';
@Component({
  selector: 'app-edit-social-media-buttons',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './edit-social-media-buttons.component.html',
  styleUrl: './edit-social-media-buttons.component.scss',
  providers: [NotificationService],
})
export class EditSocialMediaButtonsComponent implements OnInit, OnChanges {
  @Input() section?: EventRSVPConfirmationPageSection;
  @Input() isEditMode?: boolean = true;
  sectionMediaTypes: SocialMediaType[] = [];
  socialMediaTypes = SocialMediaType.getSocialMediaTypes();
  iconsColor?: RSVPConfirmationPageSectionIconsColorType;

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private clipboard: Clipboard,
  ) {}
  ngOnInit(): void {
    if (this.section) {
      this.section.socialMediaLinks?.forEach((item) => {
        if (item.link.trim() !== '') {
          this.sectionMediaTypes.push(item.type);
        }
      });
      if (this.section.iconsColor) {
        this.iconsColor = this.section.iconsColor;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['section']) {
      changes['section'].currentValue.socialMediaLinks?.forEach(
        (item: EventSocialMediaLink) => {
          if (
            item.link.trim() === '' &&
            this.socialMediaTypes.includes(item.type)
          ) {
            const index = this.sectionMediaTypes.indexOf(item.type);
            if (index > -1) {
              this.sectionMediaTypes.splice(index, 1);
            }
          } else if (
            !this.socialMediaTypes.includes(item.type) &&
            item.link &&
            item.link.trim() !== ''
          ) {
            this.sectionMediaTypes.push(item.type);
          } else {
            const index = this.sectionMediaTypes.indexOf(item.type);
            if (index > -1) {
              this.sectionMediaTypes.splice(index, 1);
            }
            this.sectionMediaTypes.push(item.type);
          }
        },
      );
      this.iconsColor = changes['section'].currentValue.iconsColor;
    }
  }
  missingLink(type: SocialMediaType) {
    return !this.sectionMediaTypes.includes(type);
  }

  getThemeIconColors(): string {
    if (
      this.iconsColor === undefined ||
      this.iconsColor === RSVPConfirmationPageSectionIconsColorType.THEME
    ) {
      const color = document.documentElement.style.getPropertyValue(
        '--event-theme-primary-color',
      );
      return Helpers.getTextColorForBg(color);
    } else return '';
  }

  copyLink(type: SocialMediaType) {
    if (!this.isEditMode) {
      let linkItem = this.section?.socialMediaLinks?.find(
        (x) => x.type === type,
      );
      if (linkItem) {
        this.clipboard.copy(linkItem.link);
        this.notificationService.successWithClass(
          this.translateService.instant(
            'APP.AFFILIATE_PRODUCTS_PAGE_LIST.SUCCESS.LINK_COPIED',
          ),
          'toast-success-white-event-primary',
        );
      }
    }
  }

  protected readonly SocialMediaType = SocialMediaType;
  protected readonly RSVPConfirmationPageSectionIconsColorType =
    RSVPConfirmationPageSectionIconsColorType;
}
