@if (affiliateProduct) {
  <div class="affiliate-product-card" [class.edit-view]="editView">
    <div class="product-info">
      @if (affiliateProduct.pictureUrl) {
        <img
          class="product-image"
          [src]="affiliateProduct.pictureUrl"
          alt="Product Image"
        />
      }

      <!-- drag handle -->
      <ng-content></ng-content>

      @if (editView) {
        <div class="delete-icon" (click)="removeProduct()">
          <i class="far fa-trash-alt"></i>
        </div>
      } @else {
        <div class="ad-banners">
          <div class="ad-banner">{{ "APP.AD" | translate }}</div>
          <div class="ad-banner">+500 {{ "APP.SOLD" | translate }}</div>
        </div>
      }

      <div class="product-name">{{ affiliateProduct.name }}</div>
      <div class="product-description">{{ affiliateProduct.description }}</div>
    </div>

    <div class="action-cont btn" (click)="onBuy()">
      @if (editView) {
        {{ affiliateProduct.affiliateProductStats?.totalAffiliateClicks || 0 }}
        {{ "APP.CLICKED" | translate }}
      } @else {
        {{
          "APP.BUY_ON"
            | translate
              : {
                  affiliatePartner:
                    (affiliateProduct.getAffiliateNetworkDisplayName()
                    | translate)
                }
        }}
      }
    </div>
  </div>
}
