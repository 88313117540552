<div class='email-invite-wrapper'>
  <label class='invite-title'>{{ "APP.EVENT_INVITE.ENTER_EMAIL" | translate }}</label>
  <form [formGroup]="emailForm" (keydown.enter)="invitePerson()">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{ "APP.EMAIL" | translate }}</mat-label>
      <input type="email" matInput formControlName="email" />
    </mat-form-field>
    <span class='description'>{{ "APP.EVENT_INVITE.PRESS_ENTER" | translate }}</span>
  </form>
  @if(invitedPeople.length > 0){
    <div class='invited-people'>
      @for(person of invitedPeople; track $index){
      <mat-chip-row (removed)="unInvite(person)" color='primary' class='invited-person'>
        {{ person }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
      }
    </div>
  }
</div>
