import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { MatDialogActions } from '@angular/material/dialog';
import { Event } from '@models/events/event.model';
import { TelInputComponent } from '@modules/shared/components/tel-input/tel-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModules } from '@utils/shared-modules';
import { EventRsvpQuestionComponent } from '../event-rsvp-question/event-rsvp-question.component';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { RSVPLayout } from '@models/event-attendance/rsvp-layout.enum';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { User } from '@models/users/user.model';
import { EventAttendee } from '@models/event-attendance/event-attendee.model';

@Component({
  selector: 'app-rsvp-plus-ones-step',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    EventRsvpQuestionComponent,
    TelInputComponent,
    MatDialogActions,
  ],
  templateUrl: './rsvp-plus-ones-step.component.html',
  styleUrl: './rsvp-plus-ones-step.component.scss',
})
export class RsvpPlusOnesStepComponent {
  @Input() event?: Event;
  @Input() form?: FormGroup;
  @Input() loggedUser?: User;
  @Input() layout: RSVPLayout = RSVPLayout.THREE_SMILIES;
  @Input() formAppearance: MatFormFieldAppearance = 'outline';
  @Input() isAttendee?: boolean;
  @Input() inProgress = false;

  eventPlusOnesInfo = {
    title: '',
    description: '',
    plusOneItemLabel: '',
    addLabel: '',
  };

  @Output() next = new EventEmitter<void>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.event?.eventTemplate?.rsvpSettings) {
      this.layout = this.event?.eventTemplate?.rsvpSettings?.layout;
    }
    if (
      this.event &&
      ['eltern-kind-olympiade-2024', 'woerthsee-vorschulturnen'].includes(
        this.event.uri,
      )
    ) {
      this.eventPlusOnesInfo = {
        title: 'Wer nimmt teil?',
        description:
          'Trag die Namen der teilnehmenden Kinder ein. Du kannst mehrere hinzufügen.',
        plusOneItemLabel: 'Kind',
        addLabel: 'Kind hinzufügen',
      };
    }

    const previousRSVP = this.event?.getUserAttendee(this.loggedUser?.id || 0);
    if (
      previousRSVP &&
      previousRSVP.groupMembers &&
      previousRSVP.groupMembers.length > 0
    ) {
      previousRSVP.groupMembers.forEach((groupMember) => {
        this.addPlusOne(groupMember);
      });
    } else {
      this.addPlusOne();
    }
  }

  addPlusOne(plusOneAttendee?: EventAttendee) {
    if (!this.form || !this.event) {
      return;
    }

    const plusOnes = this.form.controls['plusOnes'] as FormArray;

    if (plusOnes) {
      const plusOneFormGroup: FormGroup = this.fb.group({
        id: [plusOneAttendee?.id || ''],
        firstName: [plusOneAttendee?.firstName || ''],
        lastName: [plusOneAttendee?.lastName || ''],
        email: [plusOneAttendee?.email || ''],
        phone: [plusOneAttendee?.phone || ''],
      });

      if (this.event.eventTemplate?.attendeeQuestions) {
        for (const question of this.event.eventTemplate.attendeeQuestions) {
          plusOneFormGroup.addControl(
            `question#${question.id}`,
            this.fb.control(
              plusOneAttendee?.getQuestionAnswer(question) || undefined,
            ),
          );
        }
      }

      setTimeout(() => {
        plusOnes.push(plusOneFormGroup);
      });
    }
  }

  removePlusOne(i: number) {
    const plusOnes = this.plusOnes;
    plusOnes.removeAt(i);
  }

  onNext() {
    this.next.emit();
  }

  getFormGroup(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  get plusOnes(): FormArray {
    return this.form!.get('plusOnes') as FormArray;
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  protected readonly RSVPLayout = RSVPLayout;
}
