import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { Event } from '@models/events/event.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/shared/notification.service';
import { FormsModules } from '@utils/shared-modules';
import { ShareModule } from 'ngx-sharebuttons';

@Component({
  selector: 'app-insurance-check-share-options',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModules, ShareModule],
  providers: [NotificationService],
  templateUrl: './insurance-check-share-options.component.html',
  styleUrl: './insurance-check-share-options.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsuranceCheckShareOptionsComponent implements OnInit {
  @Input() event?: Event;
  @Input() isAfterAttend = false;
  link = signal<string | undefined>(undefined);

  constructor(
    private clipboard: Clipboard,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    if (this.event) {
      this.link.set(this.event.getEventCampaignInsuranceCheckLink());
    }
  }

  copyLink() {
    if (this.link()) {
      this.clipboard.copy(this.link()!);
      this.notificationService.success(
        this.translateService.instant(
          'APP.AFFILIATE_PRODUCTS_PAGE_LIST.SUCCESS.LINK_COPIED',
        ),
      );
    }
  }

  goToSecurityCheck() {
    if (this.link) {
      window.open(this.link(), '_blank');
    }
  }
}
