<div class="share-wrapper">
  @if (link()) {
    <div class="w-100 form-wrapper" [class.after-attend-form]="isAfterAttend">
      @if (!isAfterAttend) {
        <div class="invite-link">
          <label class="invite-title">{{
            "APP.EVENT_INVITE.INVITE_VIA_LINK" | translate
          }}</label>
          <mat-form-field appearance="outline" class="w-100 copy-button">
            <input
              class="input-link"
              type="text"
              matInput
              [ngModel]="link()"
              [readonly]="true"
            />
            <div matSuffix class="icon" (click)="copyLink()">
              <i class="far fa-copy icon-copy"></i>
            </div>
          </mat-form-field>
        </div>
      }
      <div class="invite-link">
        <label class="invite-title">
          {{ "APP.EVENT_INVITE.INVITE_VIA_SOCIALS" | translate }}
        </label>

        <div class="share-buttons">
          <i
            class="fab fa-facebook-messenger share-icon"
            shareButton="messenger"
            [url]="link()!"
          ></i>
          <i
            class="fab fa-facebook-square share-icon"
            shareButton="facebook"
            [url]="link()!"
          ></i>
          <i
            class="fab fa-whatsapp-square share-icon"
            shareButton="whatsapp"
            [url]="link()!"
          ></i>
        </div>
      </div>

      <div class="action-cont">
        <button
          class="btn btn-primary-party-page"
          (click)="goToSecurityCheck()"
        >
          {{ "APP.INSURANCE_CHECK_LINK.TO_SECURITY_CHECK_HOST" | translate }}
        </button>

        <div class="insurance-check-info">
          {{ "APP.INSURANCE_CHECK_LINK.ONLY_2_MINUTES" | translate }}
        </div>
      </div>
    </div>
  }
</div>
